<template>
  <v-container>
    <v-form v-model="valid" ref="itemForm" @submit="item_submit">
      <v-card>
        <v-card-title> Perfil Compañía </v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="item.typeid"
                hide-details="auto"
                label="Tipo Documento"
                :items="setting['type_document_identifications']"
                item-value="code"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="item.PartyIdentification"
                :rules="f_required"
                label="ID"
                hide-details="auto"
                required
                outlined
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="item.PartyName"
                label="Nombre"
                hide-details="auto"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.AddressLine"
                label="Dirección"
                hide-details="auto"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.city_code"
                hide-details="auto"
                label="Ciudad"
                :items="setting['municipalities']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.Department"
                hide-details="auto"
                label="Departamento"
                :items="setting['departments']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field
                v-model="item.client_email"
                id="name"
                :rules="f_required"
                label="Correo electrónico"
                hide-details="auto"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.client_phone"
                label="Teléfono"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.type_organization_id"
                hide-details="auto"
                label="Tipo persona"
                :items="setting['type_organizations']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.type_regime_id"
                hide-details="auto"
                label="Tipo de régimen"
                :items="setting['type_regimes']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.type_liability_id"
                hide-details="auto"
                label="Tipo de responsabilidad"
                :items="setting['type_liabilities']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                readonly
                v-model="item.store_code"
                label="Cod. POSWEB"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.sync_code"
                hide-details="auto"
                label="SYNC"
                :items="lstSync"
                item-value="id"
                item-text="name"
                outlined
                required
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item.environment"
                hide-details="auto"
                label="Ambiente"
                :items="setting['type_environments']"
                item-value="id"
                item-text="name"
                :rules="f_required"
                outlined
                required
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="red" dark @click="delete_itm(item)">
            <span>Borrar</span>
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn type="submit" class="success">
            <span>Guardar</span>
            <v-icon>mdi-content-save-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog persistent max-width="290" v-model="dialog_confirm">
      <v-card>
        <v-card-title> Confirmar </v-card-title>
        <v-card-text> Desea eliminar el registro? </v-card-text>
        <v-card-actions>
          <v-btn text color="red" type="button" @click="dialog_confirm = !dialog_confirm">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="success" type="button" @click="delete_item">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-title>
          {{ loadingStatus.title }}
        </v-card-title>
        <v-card-text class="pa-6">
          <p>{{ loadingStatus.text }}</p>
          <v-progress-linear indeterminate color="green" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, fe_ws } from "../services/webserver.js";
import createCompany from "../utils/createCompany.js";
import createSet from "../utils/createSetting.js";
import settings from "../utils/settings.js";
export default {
  name: "Company",
  components: {},
  beforeMount() {},
  mounted() {
    //this.get_settings();
    this.get_items();
  },
  data() {
    return {
      valid: false,
      loading_status: false,
      dialog_confirm: false,
      item: createCompany(),
      cityObj: createSet(),
      // setting: {
      //   countries: [],
      //   type_document_identifications: [],
      //   type_organizations: [],
      //   type_regimes: [],
      //   type_liabilities: [],
      //   municipalities: [],
      //   type_environments: [],
      //   type_documents: [],
      // },
      table_lst: [
        "countries",
        "type_document_identifications",
        "type_organizations",
        "type_regimes",
        "type_liabilities",
        "municipalities",
        "departments",
        "type_environments",
        "type_documents",
      ],
      lstSync: [
        { name: "TRUE", id: "1" },
        { name: "FALSE", id: "0" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      reg_delete: null,
      loadingStatus: {
        title: "Proceso",
        text: "Conectando con la DIAN",
      },
      setting: settings(),
    };
  },
  methods: {
    selectCityName(e) {
      this.item.city_code = e.code;
      this.item.CityName = e.name;
      console.log(e);
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var qry = {
          store: window.store.store_id,
          table: "f_companies",
          data: [this.item],
        };
        this.loadingStatus = {
          title: "Proceso",
          text: "Actualizando registro.",
        };
        this.loading_status = true;
        webserver("put_dataTable", qry, (data) => {
          this.loading_status = false;
          //this.resetDoc();
          //this.loading_vendors = false;
          console.log(data);
          //this.print_doc(data);
        });
      }
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: "f_company",
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      console.log(qry);
      // webserver("delete_record", qry, () => {
      //   this.reg_delete = null;
      //   //this.dialog_confirm = false;
      //   this.loading_status = false;
      //   this.$emit("refresh", true);
      // });
    },
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop] > b[prop]) {
          return 1;
        } else if (a[prop] < b[prop]) {
          return -1;
        }
        return 0;
      };
    },
    get_items() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
        ],
      };
      this.loadingStatus = {
        title: "Proceso",
        text: "Conectando con la DIAN",
      };
      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        this.item.store_code = window.store.store_id;
        if (data.length > 0) {
          console.log("get_dataTable>", data);
          this.item = data[0];
          this.cityObj = { name: data[0].CityName, code: data[0].city_code };
        }
      });
    },
    async get_settings() {
      this.itms = [];
      var qry = {};
      var tbl = "";
      this.loading_status = true;
      for (tbl of this.table_lst) {
        if (tbl) {
          qry = {
            table: "settings",
            filters: [
              {
                field: "tableName",
                operator: "=",
                value: tbl,
              },
            ],
          };
          let promise = new Promise((resolve, reject) => {
            fe_ws(
              "get_table",
              qry,
              function (data) {
                resolve(data);
              },
              function () {
                reject([]);
              }
            );
          });

          var result = await promise;
          console.log(result);
          // result.forEach((row) => {
          //   row.id = parseInt(row.id);
          // });
          // console.log(result);
          if (result) {
            result.sort(function (a, b) {
              return parseFloat(a.name) - parseFloat(b.name);
            });
            this.itms.push.apply(this.itms, result);
          }
        }
      }
      this.loading_status = false;
      var rstd = this.itms.reduce(function (rv, x) {
        (rv[x["tableName"]] = rv[x["tableName"]] || []).push(x);
        return rv;
      }, {});
      // for (const [key, value] of Object.entries(rstd)) {
      //   console.log(key);
      //   value.sort(this.GetSortOrder("name"));
      // }

      this.setting = rstd;
      this.get_items();
    },
  },
};
</script>
