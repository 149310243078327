<template>
  <v-container>
    <v-data-table
      :headers="headers_cash"
      :items="cash_type"
      :items-per-page="10"
      item-key="id"
      sort-by="name"
      mobile-breakpoint="0"
      class="elevation-4"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar flat>
          <h3>Reporte de Caja</h3>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="issueDate"
            label="Fecha"
            hide-details="auto"
            required
            outlined
            type="date"
            :rules="f_required"
            dense
            append-outer-icon="mdi-magnify"
            @click:append-outer="get_report"
          />
        </v-toolbar>

        <v-row class="pa-2">
          <v-col cols="12" md="3"> </v-col>
        </v-row>

        <v-card>
          <v-card-title>Tipo de Eventos</v-card-title>
          <GChart type="ColumnChart" :data="chartPayments" :options="chartOptions" />
        </v-card>
      </template>
      <template v-slot:[`item.qty`]="{ item }">
        <span>{{ parseFloat(item.qty).toLocaleString("es") }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ parseFloat(item.value).toLocaleString("es") }}</span>
      </template>
      <template slot="body.append">
        <tr>
          <th class="title">Total</th>
          <th class="text-center">{{ sumTable("cash_type", "qty") }}</th>
          <th class="text-right">{{ sumTable("cash_type", "value") }}</th>
        </tr>
      </template>
    </v-data-table>

    <v-data-table
      :headers="headers"
      :items="cash_data"
      :items-per-page="10"
      mobile-breakpoint="0"
      item-key="id"
      sort-by="name"
      class="elevation-4 mt-6"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar flat>
          <h1>Documentos</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:[`item.qty`]="{ item }">
        <span>{{ parseFloat(item.qty).toLocaleString("es") }}</span>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <span>{{ parseFloat(item.value).toLocaleString("es") }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="showInvoice(item)"> mdi-magnify </v-icon>
      </template>
      <template slot="body.append">
        <!-- <tr>
          <th class="title">Total</th>
          <th></th>
          <th class="text-center ">{{ sumTable('cash_data', 'quantity') }}</th>
          <th class="text-right ">{{ sumTable('cash_data', 'descuento') }}</th>
          <th class="text-right ">{{ sumTable('cash_data', 'valor') }}</th>
        </tr> -->
      </template>
    </v-data-table>
    <docViewer
      :item="item"
      :dialog="dialog"
      @close="dialog = false"
      @refresh="refresh()"
    />
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import docViewer from "../components/doc_viewer.vue";
import createDoc from "../utils/create_doc.js";
import { GChart } from "vue-google-charts";

export default {
  components: { docViewer, GChart },
  data() {
    return {
      f_required: [(v) => !!v || "Requerido"],
      loading_status: false,
      searchvalid: false,
      issueDate: getToday(),
      headers: [
        {
          text: "Documento ID",
          align: "start",
          sortable: true,
          value: "order_num",
          dataType: "text",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "client_name",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "valor",
          dataType: "number",
        },

        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_cash: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "qty",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "value",
          dataType: "number",
        },
      ],
      cash_data: [],
      cash_type: [],
      dialog: false,
      item: createDoc(),
      chartPayments: ["Evento", "Cantidad"],
      chartOptions: {
        curveType: "function",
        legend: { position: "right" },
        interpolateNulls: true,
        crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
        title: "",
        isStacked: false,
        hAxis: {
          title: "",
          slantedText: true,
          slantedTextAngle: 15,
        },
        colors: ["#1b9e77", "#d95f02", "#7570b3"],
        height: 400,
      },
    };
  },
  mounted() {},
  methods: {
    sumTable(table, key) {
      // sum data in give key (property)
      return parseFloat(
        this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
      ).toLocaleString(2);
    },
    showInvoice(e) {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dateTo: e.order_date,
        did: e.order_number,
      };
      webserver("get_invoice", qry, (data) => {
        console.log(data);
        this.item = { ...data };
        this.dialog = true;
        this.loading_status = false;
      });
    },
    get_report() {
      this.loading_status = true;

      var qry = {
        store: window.store.store_id,
        date: this.issueDate,
        userId: window.profile.user_email,
      };

      webserver("get_cashdrawer_report", qry, (data) => {
        var result = [];
        var dtSet = [];
        data.reduce(function (res, value) {
          if (!res[value.tipo]) {
            res[value.tipo] = { label: value.tipo, qty: 0, value: 0 };
            result.push(res[value.tipo]);
          }
          res[value.tipo].qty += 1;
          res[value.tipo].value += parseFloat(value.valor);
          return res;
        }, {});

        result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
        this.cash_type = result;

        dtSet.push(["Forma de pago", "Valor"]);
        result.forEach((rg) => {
          var item = [];
          item.push(rg.label);
          item.push(rg.value);
          dtSet.push(item);
        });

        this.chartPayments = dtSet;

        this.loading_status = false;
        //this.cash_data = data;
      });
    },
  },
};
</script>

<style></style>
