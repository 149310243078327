<template>
  <v-navigation-drawer right class="navigation-drawer" :value="dialog" width="400px" app>
    <template v-slot:prepend>
      <v-toolbar>
        <v-toolbar-title>Documentos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog"> mdi-close </v-icon>
      </v-toolbar>
    </template>
    <v-card>
      <v-card-text class="pa-3 text-center">
        <div ref="printSection" id="printSection">
          <v-img :src="invoiceImg"></v-img>
          <v-img
            :src="urlCufe"
            id="CufeQR"
            width="120px"
            v-if="item.document.cufe"
          ></v-img>
        </div>
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <v-toolbar class="fixed-footer">
        <v-speed-dial
          v-model="fab"
          direction="top"
          open-on-clic
          transition="slide-y-reverse-transition"
          class="mr-2"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="success" dark> DIAN </v-btn>
          </template>
          <!-- Nata Credito -->
          <v-btn
            block
            color="success"
            @click="edit_nc()"
            class="ml-12"
            v-if="
              item.document.doc_type === 'invoice' &&
              item.document.cufe &&
              item.document.doc_reference === null
            "
          >
            Nota Crédito
          </v-btn>
          <v-btn
            block
            color="success"
            class="ml-12"
            @click="e_credit_note(item)"
            v-if="item.document.doc_type === 'NC'"
          >
            Enviar NC
          </v-btn>
          <!-- Reenviar -->
          <v-btn
            block
            color="success"
            @click="reprocessInvoice()"
            v-if="item.document.doc_type === 'invoice'"
            class="ml-12"
          >
            Reenviar Dian
          </v-btn>
          <!-- e_invoice_fe -->
          <v-btn
            color="success"
            @click="send_invoice(item.document.cufe)"
            v-if="
              item.document.doc_type === 'invoice' &&
              item.buyer.PartyEmail &&
              item.document.cufe
            "
          >
            Enviar Correo
          </v-btn>
        </v-speed-dial>

        <v-btn color="info" class="mr-2" @click="print_ticket(true)">
          Ticket Cambio
        </v-btn>
        <v-btn
          color="info"
          @click="print_invoice(true)"
          v-if="item.document.invoice !== ''"
        >
          Imprimir FE
        </v-btn>
        <v-btn color="success" @click="reprocessInvoice()" v-else> Imprimir FE </v-btn>
      </v-toolbar>
    </template>

    <v-dialog
      v-model="dian_dialog"
      transition="dialog-fade-transition"
      scrollable
      width="480px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="success">
            DIAN
            <v-spacer></v-spacer>
            <v-icon @click="dian_dialog = !dian_dialog"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                readonly
                v-model="dian_response.status_description"
                label="Descripción"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                readonly
                v-model="dian_response.status_message"
                label="Descripción"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                readonly
                v-model="dian_response.issue_date"
                label="Fecha"
                hide-details="auto"
                outlined
              />
            </v-col>
            <v-col cols="12" md="12" v-if="dian_response.is_valid">
              <iframe
                :src="dian_response.qr_link"
                width="100%"
                height="80vh"
                frameborder="0"
                class="cart-list"
              >
              </iframe>
            </v-col>
          </v-row>
          <v-btn
            light
            color="success"
            x-large
            :href="dian_response.pdf_download_link"
            target="_blank"
          >
            Descargar PDF
          </v-btn>
        </v-card-text>
        <v-card-text class="pt-4" v-if="dian_response.message">
          <v-alert outlined type="error">
            {{ dian_response.payload }}
          </v-alert>
          <!-- <v-textarea label="Errors" v-model="dian_response.errors"></v-textarea> -->
          <pre>{{ JSON.stringify(dian_response.errors, null, 2) }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading_status" hide-overlay persistent width="480px">
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          <p>Respuesta</p>
          <p>Conectando con DIAN.</p>
          <v-progress-linear indeterminate color="green" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_erros" dark persistent width="480px">
      <v-card color="primary">
        <v-card-title> Alerta </v-card-title>
        <v-card-text>
          <pre>{{ JSON.stringify(dian_response.errors, null, 2) }}</pre>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer
          ><v-btn @click="dialog_erros = !dialog_erros"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_mail"
      dark
      persistent
      width="480px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card color="green">
        <v-card-title> {{ mail_response.status_description }} </v-card-title>
        <v-card-subtitle> {{ mail_response.status_message }} </v-card-subtitle>
        <v-card-text>
          <pre>{{ JSON.stringify(mail_response.mail_sending_message, null, 2) }}</pre>
          <pre>{{ JSON.stringify(mail_response.errors, null, 2) }}</pre>
          <!-- <iframe
                :src="mail_response.qr_link"
                width="100%"
                height="80vh"
                frameborder="0"
                class="cart-list"
              >
              </iframe> -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer><v-btn @click="dialog_mail = !dialog_mail"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="nc_dialog"
      transition="dialog-fade-transition"
      scrollable
      width="480px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="mx-auto">
        <v-card-title class="pa-0">
          <v-toolbar dark color="pink darken-3">
            Nota Crédito - {{ item.document.doc_number }}
            <v-spacer></v-spacer>
            <v-icon @click="nc_dialog = !nc_dialog"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 pt-4">
          <v-data-table
            v-model="selected"
            :headers="headers_nc"
            :items="nc_items"
            :items-per-page="-1"
            class="elevation-1"
            calculate-widths
            mobile-breakpoint="0"
            item-key="item_code"
            :loading="loading_status"
            loader-height="10"
            loading-text="Cargando ..."
            hide-default-footer
            show-select
          >
            <template v-slot:[`item.cantidad`]="{ item }">
              <v-btn fab icon @click="editItem(item)">
                {{ item.cantidad }}
              </v-btn>
            </template>
            <template v-slot:[`item.refe`]="{ item }">
              {{
                item.categoria +
                "-" +
                item.marca +
                "-" +
                item.refe +
                "-" +
                item.color +
                "-" +
                item.talla
              }}
            </template>
            <template v-slot:[`item.precio`]="{ item }">
              {{ "$ " + (item.precio * item.cantidad).toLocaleString(2) }}
              <br />
              <small
                class="red--text text-decoration-line-through"
                v-if="item.discount > 0"
                >{{
                  "$ " + ((item.discount + item.precio) * item.cantidad).toLocaleString(2)
                }}
              </small>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="pa-0">
          <v-toolbar>
            <v-spacer></v-spacer>
            <v-btn @click="create_nc" v-if="selected.length > 0">
              Crear Nota Crédito
            </v-btn>
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import { fe_ws } from "../services/webserver.js";
import createDoc from "../utils/create_doc.js";
import dianResponse from "../utils/dianResponse.js";
import settings from "../utils/settings.js";
import { webserver } from "../services/webserver.js";
import createCompany from "../utils/createCompany.js";
import jsPDF from "jspdf";

var QRCode = require("qrcode");

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: createDoc(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    newInvoice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    // console.log("=======>", this.settings["type_liabilities"]);
    //this.get_company();
  },
  data() {
    return {
      fab: false,
      nc_dialog: false,
      nc_items: [],
      headers_nc: [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "item_concept",
          dataType: "text",
        },
        {
          text: "Qty",
          align: "center",
          sortable: true,
          value: "item_quantity",
          dataType: "text",
          width: "10",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "item_price",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      selected: [],
      invoiceImg: null,
      urlCufe: null,
      dian_dialog: false,
      dialog_mail: false,
      dian_response: dianResponse(),
      mail_response: dianResponse(),
      docview: "",
      loading_status: false,
      company: createCompany(),
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      settings: settings(),
      dialog_erros: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    reprocessInvoice() {
      if (this.item.document.invoice !== "") {
        this.e_invoice_fe();
      } else {
        var qry = {
          store: window.store.store_id,
          doc_type: this.item.document.doc_type,
          doc_number: this.item.document.doc_number,
          issuedate: this.item.document.issuedate,
        };
        console.log("reprocessInvoice", qry);
        this.loading_status = true;
        webserver("process_invoice", qry, (data) => {
          this.loading_status = false;
          this.item = data;
          this.e_invoice_fe();
        });
      }
    },
    edit_nc() {
      console.log("edit_nc", this.item);
      this.nc_items = this.item.items;
      this.nc_dialog = true;
    },
    get_company() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "store_business_id",
            operator: "=",
            value: window.store.store_business_id,
          },
        ],
      };
      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        // console.log("get_company", data);
        this.loading_status = false;
        if (data.length > 0) {
          this.company = data[0];
        }
      });
    },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%"/><br>');
      if (this.urlCufe) {
        a.document.write('<img src="' + this.urlCufe + '"  width="200px"/>');
      }
      // a.document.write('<img src="' + this.urlCufe + '"  width="200px"/>');
      a.document.close();

      setTimeout(() => {
        a.print();
        setTimeout(() => {
          a.close();
        }, 500);
      }, 800); // Espera de 3 segundos antes de imprimir
    },
    print_tick() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%"/><br>');
      a.document.close();
      setTimeout(() => {
        a.print();
        setTimeout(() => {
          a.close();
        }, 500);
      }, 800); // Espera de 3 segundos antes de imprimir
    },
    printDoc() {
      var printContents = document.getElementById("printSection").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
    },
    print_pdf(data) {
      var url_cufe =
        "https://catalogo-vpfe-hab.dian.gov.co/document/searchqr?documentkey=";

      if (data.company.environment === "1") {
        url_cufe = "https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=";
      }

      try {
        var LS = 4;
        var y = 5;
        var x = 5;
        var itemsQty = data.items.length * LS;
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: [80, 160 + itemsQty],
        });

        pdf.setFontType = "bold";
        pdf.setFontSize(14);
        pdf.text(x + 35, y, data.company.PartyName, "center");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;

        pdf.text(
          x + 35,
          y,
          this.settings["type_document_identifications"].find(
            (item) => item.code == data.company.typeid
          ).name +
            " " +
            data.company.PartyIdentification,
          "center"
        );

        y += LS;
        pdf.text(x + 35, y, data.company.AddressLine, "center");
        y += LS;
        pdf.text(
          x + 35,
          y,
          data.company.CityName + ", " + data.company.Department,
          "center"
        );

        var regimen = "NO RESPONSABLES DEL IVA";
        if (data.company.TaxLevelCode) {
          switch (data.company.TaxLevelCode) {
            case "0":
              regimen = "NO RESPONSABLES DEL IVA";
              break;
            case "1":
              regimen = "RESPONSABLES DEL IVA";
              break;
            default:
              regimen = "NO RESPONSABLES DEL IVA";
          }
        }
        y += LS;
        pdf.text(x + 35, y, regimen, "center");
        y += LS;
        pdf.text(
          x + 35,
          y,
          "Resolución DIAN: " + data.permit.InvoiceAuthorization,
          "center"
        );
        y += LS;
        pdf.text(
          x + 35,
          y,
          "Del: " +
            data.permit.Prefix +
            "-" +
            data.permit.AuthorizationFrom +
            " al " +
            data.permit.Prefix +
            "-" +
            data.permit.AuthorizationTo,
          "center"
        );
        y += LS;
        pdf.text(
          x + 35,
          y,
          "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
          "center"
        );
        y += LS;

        y += LS;
        pdf.text(x + 35, y, "Documento Equivalente a la Factura de Venta", "center");

        y += LS;
        pdf.text(x + 35, y, "No. " + data.document.invoice, "center");
        y += LS;
        pdf.text(
          x + 35,
          y,
          this.settings["type_liabilities"].find(
            (item) => item.id == data.company.type_liability_id
          ).name,
          "center"
        );
        y += LS;
        pdf.text(
          x + 35,
          y,
          this.settings["type_organizations"].find(
            (item) => item.id == data.company.type_organization_id
          ).name,
          "center"
        );
        y += LS;
        pdf.text(
          x + 35,
          y,
          this.settings["type_regimes"].find(
            (item) => item.id == data.company.type_regime_id
          ).name,
          "center"
        );

        y += LS;
        pdf.text(x + 35, y, data.document.issuedate, "center");
        y += LS;
        y += LS;
        pdf.setFontSize(12);
        pdf.text(x, y, data.document.PartyName, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          x,
          y,

          data.document.PartyIdentification,
          "left"
        );
        y += LS;

        // pdf.text(120, y, data.document.party_address, "left");
        y += LS;
        // pdf.text(120, y, data.document.party_city, "left");
        y += LS;
        pdf.text(
          x,
          y,
          data.document.PartyEmail + " Tel: " + data.buyer.PartyPhone,
          "left"
        );
        y += LS;
        // pdf.text(120, y, data.document.party_note, "left");
        y += LS;
        pdf.setFontSize(10);
        pdf.setFontType = "normal";
        pdf.text(x + 5, y, "Cant.", "center");
        pdf.text(15, y, "Producto", "left");
        // pdf.text(x, y, "Cant.", "center");
        // pdf.text(x, y, "Precio", "right");
        // pdf.text(x, y, "Impuesto", "right");
        pdf.text(75, y, "Total", "right");
        y += LS;

        data.items.forEach((row) => {
          //console.log(row);
          var price = row.item_price * row.item_quantity;

          pdf.textAlign = "start";
          pdf.text(x, y, row.item_quantity, "left");
          pdf.textAlign = "start";
          pdf.text(x + 10, y, row.item_concept, "left");
          pdf.textAlign = "end";
          pdf.text(75, y, price.toLocaleString(), "right");
          y += LS;
          if (row.item_discount > 0) {
            var discount = row.item_discount * row.item_quantity;
            pdf.textAlign = "start";
            pdf.text(x, y, "Descuento : " + discount, "left");
          }
        });
        if (data.document.cufe) {
          QRCode.toDataURL(url_cufe + data.document.cufe, function (err, url) {
            pdf.addImage(url, "png", 20, y, 80, 80);
          });
          y += 45;
          pdf.text(x + 35, y, "CUFE:", "center");

          y += LS;
          var dtext = data.document.cufe.replace(/<[^>]+>/g, "");
          var splitTitle = pdf.splitTextToSize(dtext, 70);
          pdf.text(x, y, splitTitle);

          y += LS;
        }
      } catch (e) {
        console.log(e);
      }

      const dataUrl = pdf.output("dataurlstring");
      console.log(dataUrl);
      this.invoiceImg = dataUrl;
      this.showPrint = true;
    },
    print_invoice(printer) {
      var data = this.item;
      console.log(data);
      var url_cufe =
        "https://catalogo-vpfe-hab.dian.gov.co/document/searchqr?documentkey=";

      if (data.company.environment === "1") {
        url_cufe = "https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=";
      }

      var LS = 50;
      var itemsQty = data.items.length * LS;
      var elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 3000 + itemsQty * 3;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      var tax_value = 1.19;
      if (data.company.type_regime_id === "2") {
        tax_value = 1;
      }

      const ctx = elem.getContext("2d");

      var items = data.items;
      items.forEach((item) => {
        item.quantity = parseInt(item.quantity);
        item.price = parseInt(item.price);
        item.discount = parseInt(item.discount);
      });
      var regimen = "NO RESPONSABLES DEL IVA";

      if (data.seller.TaxLevelCode) {
        switch (data.seller.TaxLevelCode) {
          case "0":
            regimen = "NO RESPONSABLES DEL IVA";
            break;
          case "1":
            regimen = "RESPONSABLES DEL IVA";
            break;
          default:
            regimen = "NO RESPONSABLES DEL IVA";
        }
      }

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(data.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(data.seller.PartyName, center, line);
      line += LS;
      // var typeID = null;
      //console.log("======>",data.company);
      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.code == data.company.typeid
        ).name +
          " : " +
          data.company.PartyIdentification,
        center,
        line
      );
      line += LS;
      ctx.fillText(regimen, center, line);
      line += LS;

      ctx.fillText(
        data.company.AddressLine +
          ", " +
          this.settings["municipalities"].find(
            (item) => item.id == data.company.city_code
          ).name,
        center,
        line
      );
      line += LS;
      ctx.fillText("Resolución DIAN: " + data.permit.InvoiceAuthorization, center, line);
      line += LS;
      ctx.fillText(
        "Del: " +
          data.permit.Prefix +
          "-" +
          data.permit.AuthorizationFrom +
          " al " +
          data.permit.Prefix +
          "-" +
          data.permit.AuthorizationTo,
        center,
        line
      );
      line += LS;
      ctx.fillText(
        "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
        center,
        line
      );
      // line += LS;
      // ctx.font = "bold 35px Arial";
      // ctx.fillText("Documento Equivalente a la Factura de Venta", center, line);

      line += LS;
      ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
      line += LS;
      ctx.fillText("NO RETENEDORES DE IVA", center, line);

      switch (data.document.doc_type) {
        case "invoice":
          line += LS;
          ctx.fillText("FACTURA ELECTRONICA DE VENTA", center, line);
          ctx.font = "bold 40px Arial";
          line += LS;
          ctx.fillText("No. " + data.document.invoice, center, line);
          break;
        case "pos":
          line += LS;
          ctx.fillText("DOCUMENTO EQUIVALENTE", center, line);
          line += LS;
          ctx.fillText("A LA FACTURA DE VENTA", center, line);
          line += LS;
          ctx.fillText("SISTEMA POS", center, line);
          ctx.font = "bold 40px Arial";
          line += LS;
          ctx.fillText("No. " + data.document.invoice, center, line);
          break;
        case "NC":
          line += LS;
          ctx.fillText("NOTA CREDITO", center, line);
          ctx.font = "bold 40px Arial";
          line += LS;
          ctx.fillText("No. " + data.document.doc_number, center, line);
          break;
        default:
        // code block
      }

      line += LS;
      ctx.fillText(data.document.issuedate, center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      // if (data.payments[0].hora) {

      // } else {
      //   ctx.fillText(data.document.issueDate, center, line);
      // }

      line += LS;
      ctx.textAlign = "start";
      var PartyTypeId = "";
      if (data.buyer.PartyTypeId) {
        PartyTypeId = this.settings["type_document_identifications"].find(
          (item) => item.id == data.buyer.PartyTypeId
        ).name;
      }
      ctx.fillText(PartyTypeId + " : " + data.buyer.PartyIdentification, left, line);
      line += LS;
      ctx.font = "normal 35px Arial";
      ctx.fillText("Nombre / Razón Social: ", left, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText(data.buyer.PartyName, left, line);
      line += LS;
      ctx.fillText("Email     : " + data.buyer.PartyEmail, left, line);

      line += LS;
      line += LS;
      LS = 60;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Productos", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      total = 0;
      var tdiscount = 0;
      var idx = 1;
      ctx.textAlign = "start";
      ctx.fillText("Item", left, line);
      ctx.textAlign = "start";
      ctx.fillText("Detalle", left + 120, line);
      ctx.textAlign = "end";
      ctx.fillText("Cant.", right - 200, line);
      ctx.textAlign = "end";
      ctx.fillText("Valor", right, line);

      line += LS;
      items.forEach((row) => {
        var price = row.item_price * row.item_quantity;
        var discount = row.item_discount * row.item_quantity;

        var siniva = Math.round(price / tax_value);

        ctx.textAlign = "start";
        ctx.fillText(idx, left, line);
        ctx.textAlign = "start";
        ctx.fillText(row.item_concept, left + 50, line);
        ctx.textAlign = "end";
        ctx.fillText(row.item_quantity, right - 200, line);
        ctx.textAlign = "end";
        ctx.fillText(siniva.toLocaleString(), right, line);
        line += LS;
        ctx.font = " 35px Arial";
        ctx.textAlign = "start";
        ctx.fillText("Cod: " + row.item_code, left + 50, line);
        line += LS;
        idx += 1;

        if (discount > 0) {
          ctx.fillText(
            " IVA: $" + row.item_tax + " / Descuento: $" + discount.toLocaleString(),
            left + 50,
            line
          );
        } else {
          ctx.fillText(
            "IVA " +
              Math.round((tax_value - 1) * 100) +
              "% :" +
              row.item_tax.toLocaleString(),
            left + 50,
            line
          );
        }
        line += LS;
        ctx.font = "bold 40px Arial";
        total += price;
        tdiscount += discount;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      var sin_iva = Math.round(total / tax_value);
      var iva = total - sin_iva;
      ctx.textAlign = "start";
      ctx.fillText("Base gravable", left, line);
      ctx.textAlign = "end";
      ctx.fillText(sin_iva.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("IVA " + Math.round((tax_value - 1) * 100) + "%", left, line);
      ctx.textAlign = "end";
      ctx.fillText(iva.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("TOTAL", left, line);
      ctx.textAlign = "end";
      ctx.fillText(total.toLocaleString(), right, line);
      line += LS;

      if (tdiscount > 0) {
        ctx.textAlign = "start";
        ctx.fillText("Descuento aplicado", left, line);
        ctx.textAlign = "end";
        ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
        line += LS;
      }
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("Foma de pago: CONTADO", left, line);
      line += LS;
      ctx.textAlign = "center";
      ctx.fillText("Medio de pago", center, line);
      line += LS;
      //console.log(data.payments);
      data.payments.forEach((row) => {
        //console.log(row);
        ctx.textAlign = "start";
        ctx.fillText(row.payment_concept + " " + row.payment_reference, left, line);
        ctx.textAlign = "end";
        ctx.fillText(parseFloat(row.payment_value).toLocaleString(), right, line);
        line += LS;
      });
      line += LS;
      ctx.textAlign = "center";
      if (window.seller.store_footer) {
        var res = window.seller.store_footer.split("<br>");
        res.forEach((item) => {
          ctx.fillText(item, center, line);
          line += LS;
        });
      } else {
        ctx.fillText("! Gracias por su compra !", center, line);
      }
      line += LS;

      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      ctx.font = " 35px Arial";

      ctx.textAlign = "center";

      ctx.fillText("Modo de Operacion  Software Propio", center, line);
      line += 35;

      ctx.fillText(data.seller.PartyName, center, line);
      line += 35;
      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.code == data.company.typeid
        ).name +
          " : " +
          data.company.PartyIdentification,
        center,
        line
      );
      line += 35;

      ctx.textAlign = "center";
      ctx.fillText("Software fabricado  por :", center, line);
      line += 35;
      ctx.fillText("NGM Corp. NIT otro país: 764763603RC001", center, line);
      line += 35;
      ctx.fillText("Nombre del Software POSWEB-", center, line);

      line += LS;
      if (data.document.cufe) {
        ctx.textAlign = "center";
        const result = data.document.cufe.match(/.{1,38}/g) ?? [];
        ctx.font = "normal 38px Arial";
        ctx.fillText("CUFE:", center, line);
        line += 35;
        result.forEach((itm) => {
          ctx.fillText(itm, center, line);
          line += 35;
        });

        QRCode.toDataURL(url_cufe + data.document.cufe, (err, url) => {
          this.urlCufe = url;
          line += 45;
        });

        if (data.document.cufe_nc) {
          const result_nc = data.document.cufe_nc.match(/.{1,38}/g) ?? [];
          ctx.font = "normal 38px Arial";
          ctx.fillText("CUFE NC:", center, line);
          line += 35;
          result_nc.forEach((itm) => {
            ctx.fillText(itm, center, line);
            line += 35;
          });
        }
      }
      line += LS;

      // ctx.fillText("Productos", center, line);
      line += LS;
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
      if (printer) this.print_doc();
    },
    print_ticket(printer) {
      //console.log(data);
      //this.urlCufe = null;
      var data = this.item;
      // var url_cufe =
      //   "https://catalogo-vpfe-hab.dian.gov.co/document/searchqr?documentkey=";

      // if (data.company.environment === "1") {
      //   url_cufe = "https://catalogo-vpfe.dian.gov.co/document/searchqr?documentkey=";
      // }

      var LS = 50;
      var itemsQty = data.items.length * LS;
      var elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 3000 + itemsQty * 3;
      var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      var tax_value = 1.19;
      const ctx = elem.getContext("2d");

      var items = data.items;
      items.forEach((item) => {
        item.quantity = parseInt(item.quantity);
        item.price = parseInt(item.price);
        item.discount = parseInt(item.discount);
      });
      var regimen = "NO RESPONSABLES DEL IVA";

      if (data.seller.TaxLevelCode) {
        switch (data.seller.TaxLevelCode) {
          case "0":
            regimen = "NO RESPONSABLES DEL IVA";
            break;
          case "1":
            regimen = "RESPONSABLES DEL IVA";
            break;
          default:
            regimen = "NO RESPONSABLES DEL IVA";
        }
      }

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(data.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(data.seller.PartyName, center, line);
      line += LS;

      ctx.fillText(
        this.settings["type_document_identifications"].find(
          (item) => item.code == data.company.typeid
        ).name +
          " : " +
          data.company.PartyIdentification,
        center,
        line
      );
      line += LS;
      ctx.fillText(regimen, center, line);
      line += LS;

      ctx.fillText(
        data.company.AddressLine +
          ", " +
          this.settings["municipalities"].find(
            (item) => item.id == data.company.city_code
          ).name,
        center,
        line
      );
      // line += LS;
      // ctx.fillText("Resolución DIAN: " + data.permit.InvoiceAuthorization, center, line);
      // line += LS;
      // ctx.fillText(
      //   "Del: " +
      //     data.permit.Prefix +
      //     "-" +
      //     data.permit.AuthorizationFrom +
      //     " al " +
      //     data.permit.Prefix +
      //     "-" +
      //     data.permit.AuthorizationTo,
      //   center,
      //   line
      // );
      // line += LS;
      // ctx.fillText(
      //   "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
      //   center,
      //   line
      // );

      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText("TICKET DE CAMBIO Y GARANTIAS", center, line);

      line += LS;
      ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
      line += LS;
      ctx.fillText("NO RETENEDORES DE IVA", center, line);

      // switch (data.document.doc_type) {
      //   case "invoice":
      //     line += LS;
      //     ctx.fillText("FACTURA ELECTRONICA DE VENTA", center, line);
      //     ctx.font = "bold 40px Arial";
      //     line += LS;
      //     ctx.fillText("No. " + data.document.invoice, center, line);
      //     break;
      //   case "pos":
      //     line += LS;
      //     ctx.fillText("DOCUMENTO EQUIVALENTE", center, line);
      //     line += LS;
      //     ctx.fillText("A LA FACTURA DE VENTA", center, line);
      //     line += LS;
      //     ctx.fillText("SISTEMA POS", center, line);
      //     ctx.font = "bold 40px Arial";
      //     line += LS;
      //     ctx.fillText("No. " + data.document.invoice, center, line);
      //     break;
      //   case "NC":
      //     line += LS;
      //     ctx.fillText("NOTA CREDITO", center, line);
      //     ctx.font = "bold 40px Arial";
      //     line += LS;
      //     ctx.fillText("No. " + data.document.doc_number, center, line);
      //     break;
      //   default:
      //   // code block
      // }

      line += LS;
      ctx.fillText(data.document.issuedate, center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      // if (data.payments[0].hora) {

      // } else {
      //   ctx.fillText(data.document.issueDate, center, line);
      // }

      line += LS;
      ctx.textAlign = "start";
      var PartyTypeId = "";
      if (data.buyer.PartyTypeId) {
        PartyTypeId = this.settings["type_document_identifications"].find(
          (item) => item.id == data.buyer.PartyTypeId
        ).name;
      }
      ctx.fillText(PartyTypeId + " : " + data.buyer.PartyIdentification, left, line);
      line += LS;
      ctx.font = "normal 35px Arial";
      ctx.fillText("Nombre / Razón Social: ", left, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText(data.buyer.PartyName, left, line);
      line += LS;
      ctx.fillText("Email     : " + data.buyer.PartyEmail, left, line);

      line += LS;
      line += LS;
      LS = 60;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Productos", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      total = 0;
      var tdiscount = 0;
      var idx = 1;
      ctx.textAlign = "start";
      ctx.fillText("Item", left, line);
      ctx.textAlign = "start";
      ctx.fillText("Detalle", left + 120, line);
      ctx.textAlign = "end";
      ctx.fillText("Cant.", right - 200, line);
      ctx.textAlign = "end";
      ctx.fillText("Valor", right, line);

      line += LS;
      items.forEach((row) => {
        var price = row.item_price * row.item_quantity;
        var discount = row.item_discount * row.item_quantity;

        var siniva = Math.round(price / tax_value);

        ctx.textAlign = "start";
        ctx.fillText(idx, left, line);
        ctx.textAlign = "start";
        ctx.fillText(row.item_concept, left + 50, line);
        ctx.textAlign = "end";
        ctx.fillText(row.item_quantity, right - 200, line);
        ctx.textAlign = "end";
        ctx.fillText(siniva.toLocaleString(), right, line);
        line += LS;
        ctx.font = " 35px Arial";
        ctx.textAlign = "start";
        ctx.fillText("Cod: " + row.item_code, left + 50, line);
        line += LS;
        idx += 1;

        if (discount > 0) {
          ctx.fillText(
            " IVA: $" + row.item_tax + " / Descuento: $" + discount.toLocaleString(),
            left + 50,
            line
          );
        } else {
          ctx.fillText(
            "IVA " +
              Math.round((tax_value - 1) * 100) +
              "% :" +
              row.item_tax.toLocaleString(),
            left + 50,
            line
          );
        }
        line += LS;
        ctx.font = "bold 40px Arial";
        total += price;
        tdiscount += discount;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      var sin_iva = Math.round(total / tax_value);
      var iva = total - sin_iva;
      ctx.textAlign = "start";
      ctx.fillText("Base gravable", left, line);
      ctx.textAlign = "end";
      ctx.fillText(sin_iva.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("IVA " + Math.round((tax_value - 1) * 100) + "%", left, line);
      ctx.textAlign = "end";
      ctx.fillText(iva.toLocaleString(), right, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("TOTAL", left, line);
      ctx.textAlign = "end";
      ctx.fillText(total.toLocaleString(), right, line);
      line += LS;

      if (tdiscount > 0) {
        ctx.textAlign = "start";
        ctx.fillText("Descuento aplicado", left, line);
        ctx.textAlign = "end";
        ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
        line += LS;
      }
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("Foma de pago: CONTADO", left, line);
      line += LS;
      ctx.textAlign = "center";
      ctx.fillText("Medio de pago", center, line);
      line += LS;
      //console.log(data.payments);
      data.payments.forEach((row) => {
        //console.log(row);
        ctx.textAlign = "start";
        ctx.fillText(row.payment_concept + " " + row.payment_reference, left, line);
        ctx.textAlign = "end";
        ctx.fillText(parseFloat(row.payment_value).toLocaleString(), right, line);
        line += LS;
      });
      line += LS;
      ctx.textAlign = "center";
      if (window.seller.store_footer) {
        var res = window.seller.store_footer.split("<br>");
        res.forEach((item) => {
          ctx.fillText(item, center, line);
          line += LS;
        });
      } else {
        ctx.fillText("! Gracias por su compra !", center, line);
      }
      line += LS;

      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      ctx.font = " 35px Arial";

      ctx.textAlign = "center";

      // ctx.fillText("Modo de Operacion  Software Propio", center, line);
      // line += 35;

      // ctx.fillText(data.seller.PartyName, center, line);
      // line += 35;
      // ctx.fillText(
      //   this.settings["type_document_identifications"].find(
      //     (item) => item.code == data.company.typeid
      //   ).name +
      //     " : " +
      //     data.company.PartyIdentification,
      //   center,
      //   line
      // );
      // line += 35;

      // ctx.textAlign = "center";
      // ctx.fillText("Software fabricado  por :", center, line);
      // line += 35;
      // ctx.fillText("NGM Corp. NIT otro país: 764763603RC001", center, line);
      // line += 35;
      // ctx.fillText("Nombre del Software POSWEB-", center, line);

      // line += LS;
      // if (data.document.cufe) {
      //   ctx.textAlign = "center";
      //   const result = data.document.cufe.match(/.{1,38}/g) ?? [];
      //   ctx.font = "normal 38px Arial";
      //   ctx.fillText("CUFE:", center, line);
      //   line += 35;
      //   result.forEach((itm) => {
      //     ctx.fillText(itm, center, line);
      //     line += 35;
      //   });

      // QRCode.toDataURL(url_cufe + data.document.cufe, (err, url) => {
      //   this.urlCufe = url;
      //   line += 45;
      // });

      //   if (data.document.cufe_nc) {
      //     const result_nc = data.document.cufe_nc.match(/.{1,38}/g) ?? [];
      //     ctx.font = "normal 38px Arial";
      //     ctx.fillText("CUFE NC:", center, line);
      //     line += 35;
      //     result_nc.forEach((itm) => {
      //       ctx.fillText(itm, center, line);
      //       line += 35;
      //     });
      //   }
      // }
      line += LS;

      // ctx.fillText("Productos", center, line);
      line += LS;
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      this.showPrint = true;
      if (printer) {
        this.print_doc();
      }
    },
    e_invoice_fe() {
      // console.log(this.item);
      // console.log("e_invoice", this.company);
      var custumer = this.item.buyer;
      var itms = this.item.items;
      var doc = this.item.document;
      var company = this.item.company;
      var syncCode = true;
      if (company.sync_code === "1") {
        syncCode = true;
      } else {
        syncCode = false;
      }
      // var custumer = this.item.buyer;
      // var itms = this.item.items;
      // var doc = this.item.document;
      var type_organization = 2;
      if (custumer.PartyTypeId === "6") {
        type_organization = 1;
      } else {
        type_organization = 2;
      }

      var eInv = {
        number: doc.invoice.split("-")[1],
        sync: syncCode,
        type_document_id: 1,
        branch_office: {
          name: this.item.company.PartyName,
          phone: this.item.company.client_phone,
          address: this.item.company.AddressLine,
          trade_name: this.item.company.PartyName,
          municipality_id: this.item.company.city_code,
        },
        customer: {
          type_document_identification_id: custumer.PartyTypeId,
          identification_number: custumer.PartyIdentification,
          type_organization_id: type_organization,
          name: custumer.PartyName,
          address: custumer.AddressLine,
          municipality_id: custumer.municipality_id,
          email: custumer.PartyEmail,
        },
        legal_monetary_totals: {
          line_extension_amount: 0,
          tax_exclusive_amount: 0,
          tax_inclusive_amount: 0,
          allowance_total_amount: 0,
          charge_total_amount: 0,
          payable_amount: 0,
        },
        invoice_lines: [],
      };

      var tax_value = 1.19;
      var taxvalue = 19.0;

      if (this.item.company.type_regime_id === "2") {
        tax_value = 1;
        taxvalue = 0;
      }

      itms.forEach((item) => {
        item.item_quantity = parseFloat(item.item_quantity);
        item.price_amount = (parseFloat(item.item_price) / tax_value).toFixed(2);
        item.item_discount = parseFloat(item.item_discount).toFixed(2);
        item.tax = (
          (parseFloat(item.price_amount) * parseFloat(item.item_quantity) * taxvalue) /
          100
        ).toFixed(2);

        //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
        item.line_extension_amount = (
          parseFloat(item.price_amount) * parseFloat(item.item_quantity)
        ).toFixed(2);
      });

      itms.forEach((item) => {
        var itm = {
          description: item.item_concept,
          unit_measure_id: 938,
          code: item.item_code,
          type_item_identification_id: 3,
          base_quantity: item.item_quantity.toFixed(6),
          invoiced_quantity: item.item_quantity.toFixed(6),
          price_amount: item.price_amount,
          line_extension_amount: item.line_extension_amount,
        };
        var leo = parseFloat(itm.line_extension_amount);
        if (leo == 0) {
          itm.reference_price_id = 1;
          itm.price_amount = parseFloat(item.item_discount).toFixed(2);
        }

        if (parseFloat(item.item_discount) > 0) {
          itm.allowance_charges = [
            {
              charge_indicator: false,
              allowance_charge_reason: "Discount",
              amount: parseFloat(item.item_discount) * parseFloat(item.item_quantity),
              base_amount: (
                (parseFloat(item.price_amount) + parseFloat(item.item_discount)) *
                parseFloat(item.item_quantity)
              ).toFixed(2),
            },
          ];
          eInv.legal_monetary_totals.allowance_total_amount += parseFloat(item.discount);
        }

        itm.tax_totals = [
          {
            tax_id: 1,
            tax_amount: item.tax,
            taxable_amount: (
              parseFloat(item.price_amount) * parseFloat(item.item_quantity)
            ).toFixed(2),
            percent: taxvalue.toFixed(2),
          },
        ];

        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itm.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          item.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.invoice_lines.push(itm);
      });

      var payments = this.item.payments;
      var bags = payments.find((item) => item.tipo == "ICB ley 1819 de 2016");
      if (bags) {
        bags.quantity = parseFloat(bags.valor) / 66;
        bags.tax = 66;
        var itmBags = {
          description: "Bolsas",
          unit_measure_id: 886,
          code: "000000000000",
          type_item_identification_id: 3,
          base_quantity: "1.000000",
          invoiced_quantity: bags.quantity.toFixed(6),
          price_amount: parseFloat(bags.valor).toFixed(2),
          line_extension_amount: "0.00",
          reference_price_id: 1,
          tax_totals: [
            {
              tax_id: 10,
              tax_amount: parseFloat(bags.valor).toFixed(2),
              taxable_amount: parseFloat(bags.quantity).toFixed(2),
              unit_measure_id: 886,
              per_unit_amount: bags.tax.toFixed(2),
              base_unit_measure: bags.quantity.toFixed(6),
            },
          ],
          //  parseFloat(bags.quantity).toFixed(2),
        };
        //
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itmBags.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          itmBags.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(itmBags.line_extension_amount) + parseFloat(bags.valor);
        eInv.invoice_lines.push(itmBags);
      }

      eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(
        2
      ); //Valores totales aplicables a la factura
      eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(
        2
      ); // Total Valor Bruto (Antes de tributos)
      eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(
        2
      );

      this.put_invoice(eInv, company);
    },
    e_credit_note(e) {
      console.log("e_credit_note", e);
      var doc = e.document;
      var itms = e.items;
      var IssueDTA = doc.issuedate_reference.split(" ")[0];
      var company = this.item.company;
      //var payments = e.payments;
      doc.city_code = e.company.city_code;

      var eInv = {
        discrepancy_response: {
          correction_concept_id: 2,
        },
        billing_reference: {
          number: doc.invoice.replace("-", ""),
          uuid: doc.cufe,
          issue_date: IssueDTA,
        },
        number: doc.doc_number.split("-")[2],
        resolution: {
          prefix: doc.doc_number.split("-")[0],
          from: 1,
          to: 10000,
        },
        sync: true,
        environment: { type_environment_id: parseInt(company.environment) },
        type_document_id: 5,
        customer: {
          identification_number: doc.PartyIdentification,
          name: doc.PartyName,
          municipality_id: doc.city_code,
          email: doc.PartyEmail,
        },
        legal_monetary_totals: {
          line_extension_amount: 0,
          tax_exclusive_amount: 0,
          tax_inclusive_amount: 0,
          allowance_total_amount: 0,
          charge_total_amount: 0,
          payable_amount: 0,
        },
        credit_note_lines: [],
      };

      var tax_value = 1.19;
      var taxvalue = 19.0;

      itms.forEach((item) => {
        item.item_quantity = parseFloat(item.item_quantity);
        //Valor del artículo o servicio
        item.price_amount = (parseFloat(item.item_price) / tax_value).toFixed(2);
        item.discount = (
          (parseFloat(item.item_discount) / tax_value).toFixed(2) *
          parseFloat(item.item_quantity)
        ).toFixed(2);
        item.tax = (
          (parseFloat(item.price_amount) * parseFloat(item.item_quantity) * taxvalue) /
          100
        ).toFixed(2);
        //Valor total de la línea (Cantidad x Precio Unidad menos descuentos más recargos que apliquen para la línea)
        item.line_extension_amount = (
          parseFloat(item.price_amount) * parseFloat(item.item_quantity)
        ).toFixed(2);
      });

      itms.forEach((item) => {
        var itm = {
          description: item.item_concept,
          unit_measure_id: 642,
          code: item.item_code,
          type_item_identification_id: 3,
          base_quantity: item.item_quantity.toFixed(6),
          invoiced_quantity: item.item_quantity.toFixed(6),
          price_amount: item.price_amount,
          line_extension_amount: item.line_extension_amount,
        };
        if (parseFloat(item.item_discount) > 0) {
          itm.allowance_charges = [
            {
              charge_indicator: false,
              allowance_charge_reason: "Discount",
              amount: item.item_discount,
              base_amount: (
                parseFloat(item.price_amount) * parseFloat(item.item_quantity)
              ).toFixed(2),
            },
          ];
          eInv.legal_monetary_totals.allowance_total_amount += parseFloat(
            item.item_discount
          );
        }
        itm.tax_totals = [
          {
            tax_id: 1,
            tax_amount: item.tax,
            taxable_amount: (
              parseFloat(item.price_amount) * parseFloat(item.item_quantity)
            ).toFixed(2),
            percent: taxvalue.toFixed(2),
          },
        ];
        eInv.legal_monetary_totals.line_extension_amount += parseFloat(
          itm.line_extension_amount
        ); //Valores totales aplicables a la factura
        eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
          item.line_extension_amount
        ); // Total Valor Bruto (Antes de tributos)
        eInv.legal_monetary_totals.tax_inclusive_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.charge_total_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.legal_monetary_totals.payable_amount +=
          parseFloat(item.line_extension_amount) + parseFloat(item.tax);
        eInv.credit_note_lines.push(itm);
      });

      // var payments = this.item.payments;
      // var bags = payments.find((item) => item.payment_concept == "ICB ley 1819 de 2016");
      // if (bags) {
      //   bags.quantity = parseFloat(bags.payment_value) / 66;
      //   bags.tax = 66;
      //   var itmBags = {
      //     description: "Bolsas",
      //     unit_measure_id: 886,
      //     code: "000000000000",
      //     type_item_identification_id: 3,
      //     base_quantity: "1.000000",
      //     invoiced_quantity: bags.quantity.toFixed(6),
      //     price_amount: parseFloat(bags.payment_value).toFixed(2),
      //     line_extension_amount: "0.00",
      //     reference_price_id: 1,
      //     tax_totals: [
      //       {
      //         tax_id: 10,
      //         tax_amount: parseFloat(bags.payment_value).toFixed(2),
      //         taxable_amount: parseFloat(bags.quantity).toFixed(2),
      //         unit_measure_id: 886,
      //         per_unit_amount: bags.tax.toFixed(2),
      //         base_unit_measure: "1.000000",
      //       },
      //     ],
      //     //  parseFloat(bags.quantity).toFixed(2),
      //   };
      //   //
      //   eInv.legal_monetary_totals.line_extension_amount += parseFloat(
      //     itmBags.line_extension_amount
      //   ); //Valores totales aplicables a la factura
      //   eInv.legal_monetary_totals.tax_exclusive_amount += parseFloat(
      //     itmBags.line_extension_amount
      //   ); // Total Valor Bruto (Antes de tributos)
      //   eInv.legal_monetary_totals.tax_inclusive_amount +=
      //     parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
      //   eInv.legal_monetary_totals.charge_total_amount +=
      //     parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
      //   eInv.legal_monetary_totals.payable_amount +=
      //     parseFloat(itmBags.line_extension_amount) + parseFloat(bags.tax);
      //   eInv.credit_note_lines.push(itmBags);
      // }

      eInv.legal_monetary_totals.line_extension_amount = eInv.legal_monetary_totals.line_extension_amount.toFixed(
        2
      ); //Valores totales aplicables a la factura
      eInv.legal_monetary_totals.tax_exclusive_amount = eInv.legal_monetary_totals.tax_exclusive_amount.toFixed(
        2
      ); // Total Valor Bruto (Antes de tributos)
      eInv.legal_monetary_totals.tax_inclusive_amount = eInv.legal_monetary_totals.tax_inclusive_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.allowance_total_amount = eInv.legal_monetary_totals.allowance_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.charge_total_amount = eInv.legal_monetary_totals.charge_total_amount.toFixed(
        2
      );
      eInv.legal_monetary_totals.payable_amount = eInv.legal_monetary_totals.payable_amount.toFixed(
        2
      );
      console.log(eInv);
      this.put_credit_note(eInv);
    },
    send_invoice(uuid) {
      var company = this.item.company;
      if (this.item.buyer.PartyEmail) {
        var qry = {
          nit: this.$store.state.seller.store_business_id,
          environment: parseInt(company.environment),
          uuid: uuid,
          data: { to: [{ email: this.item.buyer.PartyEmail }] },
          store: this.item.document.store,
        };
        //console.log(qry);
        this.dialog_mail = true;
        fe_ws("send_invoice", qry, (data) => {
          //console.log(data);
          this.mail_response = data;
          this.dialog_mail = false;
        });
      }
    },
    put_invoice(data, company) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.$store.state.seller.store_business_id,
        environment: parseInt(company.environment),
        data: data,
        store: this.item.document.store,
        issueDate: this.item.document.issuedate,
        did: this.item.document.doc_number,
      };
      fe_ws("post_invoice", qry, (data) => {
        this.loading_status = false;
        //console.log(data);
        //var resp = JSON.parse(data.data);
        //var resp = data.data;
        if (data.data.errors) {
          //console.log("error:",data);
          this.dian_response = data.data;
          this.dialog_erros = true;
        } else {
          //    var dianResponse = JSON.parse(data.data);
          //  console.log(dianResponse);
          this.item.document.cufe = data.cufe;
          //console.log("toprint",this.item);
          this.print_invoice(true);

          this.send_invoice(this.item.document.cufe);
        }

        // var dianResponse = JSON.parse(data.data);
        // if (dianResponse.zip) {
        //   this.post_status_zip(dianResponse.zip);
        // } else {
        //   this.dian_dialog = true;
        //   this.dian_response = JSON.parse(data.data);
        // }
      });
    },
    post_status_zip(zip_key) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.$store.state.seller.store_business_id,
        environment: parseInt(this.company.environment),
        zip_key: zip_key,
      };
      console.log(qry);
      fe_ws("post_status_zip", qry, (data) => {
        this.loading_status = false;
        console.log(data);
        console.log(JSON.parse(data.data));
        this.dian_dialog = true;
        this.dian_response = JSON.parse(data.data);
      });
    },
    get_e_doc() {
      var doc = {
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        did: this.item.document.doc_number,
      };
      webserver("get_invoice_fe", doc, (data) => {
        console.log("get_e_doc", data);
        this.print_invoice();
        //data.doc.doc_type = "NC";
        //data.doc.doc_number = null;
        //this.put_e_doc(data);
      });
    },
    get_e_doc_credit_note() {
      var doc = {
        store: this.item.document.store,
        issueDate: this.item.document.issueDate,
        did: this.item.document.doc_number,
      };
      webserver("get_invoice_fe", doc, (data) => {
        //console.log("get_e_doc",data);
        // this.item=data;
        this.e_credit_note(data);
        //data.doc.doc_type = "NC";
        //data.doc.doc_number = null;
        //this.put_e_doc(data);
      });
    },
    create_nc() {
      var dnc = { ...this.item };
      dnc.items = this.selected;
      var total = 0;
      dnc.items.forEach((itm) => {
        total += parseFloat(itm.item_quantity) * parseFloat(itm.item_price);
      });

      dnc.document.doc_value = total;
      var doc = {
        store: this.item.document.store,
        data: dnc,
      };

      webserver("post_note", doc, (data) => {
        this.nc_dialog = false;
        this.$emit("refresh", data);
        //this.item = data;
        // this.get_e_doc_credit_note(data);
        //this.loading_vendors = false;
      });
    },
    put_credit_note(data) {
      this.loading_status = true;
      var qry = {
        method: "POST",
        nit: this.$store.state.seller.store_business_id,
        data: data,
        store: this.item.document.store,
        issueDate: this.item.document.issuedate,
        did: this.item.document.doc_number,
      };
      this.loading_status = true;
      console.log("put_credit_note", qry);
      fe_ws("post_credit_note", qry, (data) => {
        this.loading_status = false;
        console.log(data);
        this.dian_dialog = true;
        this.dian_response = JSON.parse(data.data);
        this.loading_status = false;
      });
    },
    put_e_doc(e) {
      var doc = {
        store: this.item.document.store,
        data: e,
        action: "cancel",
      };
      webserver("put_e_doc", doc, (data) => {
        //this.e_credit_note(data);
        console.log(data);
        this.loading_vendors = false;
      });
    },
  },
  watch: {
    item: function (e) {
      if (this.newInvoice) {
        if (e.document.doc_type === "invoice" && e.document.invoice !== "") {
          this.e_invoice_fe();
        } else {
          this.loading_status = true;
          setTimeout(() => {
            this.loading_status = false;
            this.dian_response.errors = [
              {
                code: "",
                description: "Fallo en la comunicacion con la DIAN",
              },
              {
                code: "",
                description: "Intente nuevamente en unos minutos",
              },
            ];
            this.dialog_erros = true;
            setTimeout(() => {
              this.dialog_erros = false;
              this.urlCufe = null;
              this.print_ticket(true);
            }, 1000);
          }, 1000);
        }
      } else {
        if (e.document.doc_type === "invoice" && e.document.invoice !== "") {
          this.print_invoice();
        } else {
          this.urlCufe = null;
          this.print_ticket();
        }
      }
    },
  },
};
</script>
