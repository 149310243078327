<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <GChart type="PieChart" :data="chartSales" :options="chartOptions" />
      </v-col>
      <v-col cols="12" md="6">
        <GChart type="PieChart" :data="chartInventory" :options="chartOptions" />
      </v-col>
      <v-col md="6">
        <v-card height="100%">
          <v-card-title>Ventas</v-card-title>
          <v-data-table
            :headers="header_sales"
            :items="sales"
            :items-per-page="5"
            item-key="category"
            mobile-breakpoint="0"
            calculate-widths
            sort-by="category"
            class="elevation-0"
            loading-text="Cargando ..."
            dense
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              {{ "$ " + parseFloat(item.discount).toLocaleString(2) }}
            </template>
            <template slot="body.append">
              <tr>
                <th class="title">Total</th>
                <th class="text-right">{{ sumTable("sales", "quantity") }}</th>
                <th class="text-right">{{ sumTable("sales", "discount") }}</th>
                <th class="text-right">{{ sumTable("sales", "price") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card height="100%" color="deep-orange lighten-4">
          <v-card-title>Inventario</v-card-title>
          <v-data-table
            :headers="header_inventory"
            :items="inventory"
            :items-per-page="5"
            item-key="category"
            mobile-breakpoint="0"
            calculate-widths
            sort-by="category"
            class="elevation-0"
            loading-text="Cargando ..."
            dense
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              {{ "$ " + parseFloat(item.discount).toLocaleString(2) }}
            </template>
            <template slot="body.append">
              <tr>
                <th class="title">Total</th>
                <th class="text-right">{{ sumTable("inventory", "quantity") }}</th>
                <th class="text-right">{{ sumTable("inventory", "price") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card height="100%" color="cyan lighten-5">
          <v-card-title>Formas de pago</v-card-title>
          <v-data-table
            :headers="header_payments"
            :items="payments"
            :items-per-page="5"
            item-key="label"
            mobile-breakpoint="0"
            sort-by="category"
            class="elevation-0"
            loading-text="Cargando ..."
            dense
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.value`]="{ item }">
              {{ "$ " + parseFloat(item.value).toLocaleString(2) }}
            </template>
            <template slot="body.append">
              <tr>
                <th class="title">Total</th>
                <th class="text-right">{{ sumTable("payments", "quantity") }}</th>
                <th class="text-right">{{ "$ " + sumTable("payments", "value") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card height="100%" color="lime lighten-5">
          <v-card-title>Eventos</v-card-title>
          <v-data-table
            :headers="header_events"
            :items="events"
            :items-per-page="5"
            item-key="label"
            mobile-breakpoint="0"
            sort-by="category"
            class="elevation-0"
            loading-text="Cargando ..."
            dense
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
            </template>
            <template slot="body.append">
              <tr>
                <th class="title">Total</th>
                <th class="text-right">{{ sumTable("events", "quantity") }}</th>
                <th class="text-right">{{ "$ " + sumTable("events", "price") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card height="100%" color="green lighten-5">
          <v-card-title>Ingreso</v-card-title>
          <v-data-table
            :headers="header_events"
            :items="cin"
            :items-per-page="5"
            item-key="label"
            mobile-breakpoint="0"
            sort-by="category"
            class="elevation-0"
            loading-text="Cargando ..."
            dense
          >
            <template v-slot:[`item.quantity`]="{ item }">
              {{ parseFloat(item.quantity).toLocaleString(2) }}
            </template>
            <template v-slot:[`item.price`]="{ item }">
              {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
            </template>
            <template slot="body.append">
              <tr>
                <th class="title">Total</th>
                <th class="text-right">{{ sumTable("cin", "quantity") }}</th>
                <th class="text-right">{{ "$ " + sumTable("cin", "price") }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card height="100%" color="red lighten-5">
          <v-card-title>Egresos</v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="header_events"
              :items="cout"
              :items-per-page="5"
              item-key="label"
              mobile-breakpoint="0"
              sort-by="category"
              class="elevation-0"
              loading-text="Cargando ..."
              dense
            >
              <template v-slot:[`item.quantity`]="{ item }">
                {{ parseFloat(item.quantity).toLocaleString(2) }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                {{ "$ " + parseFloat(item.price).toLocaleString(2) }}
              </template>
              <template slot="body.append">
                <tr>
                  <th class="title">Total</th>
                  <th class="text-right">{{ sumTable("cout", "quantity") }}</th>
                  <th class="text-right">{{ "$ " + sumTable("cout", "price") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card height="100%">
          <v-card-title>Resumen</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="header_resume"
              :items="resume"
              item-key="label"
              sort-by="label"
              mobile-breakpoint="0"
              class="elevation-0"
              loading-text="Cargando ..."
              dense
              hide-default-footer
            >
              <template v-slot:[`item.quantity`]="{ item }">
                {{ parseFloat(item.quantity).toLocaleString(2) }}
              </template>
              <template v-slot:[`item.value`]="{ item }">
                {{ "$ " + parseFloat(item.value).toLocaleString(2) }}
              </template>
              <template slot="body.append">
                <tr>
                  <th class="title">Total</th>
                  <th class="text-right">{{ "$ " + sumTable("resume", "value") }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-6">
          <p>Proceso</p>
          <p>{{ this.lStatus }}</p>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
import { GChart } from "vue-google-charts";
import createDoc from "../utils/create_doc.js";
export default {
  components: { GChart },
  data() {
    return {
      loading_status: false,
      header_sales: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "discount",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      header_inventory: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      header_events: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "event",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "price",
          dataType: "number",
        },
      ],
      header_payments: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "type",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "value",
          dataType: "number",
        },
      ],
      header_resume: [
        {
          text: "Concepto",
          align: "start",
          sortable: true,
          value: "label",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "value",
          dataType: "number",
        },
      ],
      cash_data: [],
      cash_type: [],
      dialog: false,
      item: createDoc(),
      lStatus: "",
      settings: null,
      sales: [],
      inventory: [],
      events: [],
      payments: [],
      cin: [],
      cout: [],
      totalSales: 0,
      totalDiscounts: 0,
      totalCambio: 0,
      totalGarantia: 0,
      resume: [
        { label: "Ventas totales", value: 0 },
        { label: "Descuentos", value: 0 },
        { label: "Cambios", value: 0 },
        { label: "Garantías", value: 0 },
      ],
      chartData: [],
      chartOptions: {
        curveType: "function",
        legend: { position: "right" },
        interpolateNulls: true,
        crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
        title: "",
        pieHole: 0.4,
        isStacked: false,
        hAxis: {
          title: "",
          slantedText: true,
          slantedTextAngle: 15,
        },
        height: 300,
      },
      chartInventory: ["Categoría", "Cantidad"],
      chartSales: ["Categoría", "Cantidad"],
    };
  },
  mounted() {
    console.log(window.settings);
    this.get_report();
    this.settings = window.settings;
  },
  methods: {
    sumTable(table, key) {
      // sum data in give key (property)
      return parseFloat(
        this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)
      ).toLocaleString(2);
    },
    async get_report() {
      this.loading_status = true;
      this.totalSales = 0;
      this.totalDiscounts = 0;
      this.totalCambio = 0;
      this.totalGarantia = 0;
      var reports = ["sales", "inventory", "payments", "events", "cashIN", "cashOUT"];

      var report = "";
      for (report of reports) {
        var qry = {};
        qry.store = window.store.store_id;
        qry.issueDate = getToday();
        qry.report = report;
        console.log(qry);
        this.lStatus = "Consultando : " + qry.report;
        let promise = new Promise((resolve, reject) => {
          webserver(
            "cash_register_report_b",
            qry,
            function (data) {
              resolve(data);
            },
            function () {
              reject([]);
            }
          );
        });
        let data = await promise;
        var result = [];
        var dtSet = [];
        switch (report) {
          case "sales":
            data.forEach((item) => {
              item.category = this.settings["CATEGORIA"].find(
                (itm) => itm.codigo == item.categoria
              ).valor;
              item.quantity = parseFloat(item.quantity);
              this.totalSales += parseFloat(item.price);
              this.totalDiscounts += parseFloat(item.discount);
            });
            this.sales = data;

            data.reduce(function (res, value) {
              if (!res[value.category]) {
                res[value.category] = { label: value.category, value: 0 };
                result.push(res[value.category]);
              }
              res[value.category].value += parseFloat(value.quantity);
              return res;
            }, {});

            result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

            dtSet.push(["Categoría", "Cantidad"]);
            result.forEach((rg) => {
              var item = [];
              item.push(rg.label);
              item.push(rg.value);
              dtSet.push(item);
            });

            this.chartSales = dtSet;

            break;

          case "inventory":
            data.forEach((item) => {
              item.category = this.settings["CATEGORIA"].find(
                (itm) => itm.codigo == item.categoria
              ).valor;
              item.quantity = parseFloat(item.quantity);
            });

            this.inventory = data;
            data.reduce(function (res, value) {
              if (!res[value.category]) {
                res[value.category] = { label: value.category, value: 0 };
                result.push(res[value.category]);
              }
              res[value.category].value += parseFloat(value.quantity);
              return res;
            }, {});

            result.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

            dtSet.push(["Categoría", "Cantidad"]);
            result.forEach((rg) => {
              var item = [];
              item.push(rg.label);
              item.push(rg.value);
              dtSet.push(item);
            });

            this.chartInventory = dtSet;

            break;

          case "payments":
            console.log(data);
            data.forEach((item) => {
              if (item.type === "CAMBIO") this.totalCambio += parseFloat(item.value);
              if (item.type === "GARANTIA") this.totalGarantia += parseFloat(item.value);
            });
            this.payments = data;
            break;
          case "events":
            this.events = data;
            break;
          case "cashIN":
            console.log(data);
            this.cin = data;
            break;
          case "cashOUT":
            console.log(data);
            this.cout = data;
            break;
        }
      }
      this.loading_status = false;
    },
  },
};
</script>

<style></style>
