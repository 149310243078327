<template>
  <v-app id="inspire">
    <v-navigation-drawer
      class="navigation-drawer"
      :value="showDrawer"
      @input="(val) => (drawer = val)"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">{{
            $store.getters.seller.PartyName
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $store.getters.profile.user_access
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-treeview
        return-object
        item-key="id"
        hoverable
        activatable
        :open="open"
        open-on-click
        @update:active="updateForm"
        @update:open="updateOpen"
        color="warning"
        :items="items"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon :color="item.color" v-else>
            {{ icons[item.icon] }}
          </v-icon>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-app-bar :value="$store.getters.getNavStatus" app>
      <!-- <v-icon large @click="drawer = !drawer">mdi-menu</v-icon> -->
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
        >{{ this.$store.state.seller.PartyName }} /
        {{ this.$store.state.seller.store_name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu open-on-hover transition="slide-x-transition" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar color="teal darken-1">
              <img :src="$store.getters.profilePicture" />
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="400" tile>
          <v-card-title>
            {{ this.$store.state.profile.name }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.$store.state.profile.email }}
          </v-card-subtitle>
          <v-card-actions>
            <v-btn v-on:click="logout" block> Logout </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="bg-pos">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Una actualización disponible.
      <v-btn text @click="refreshApp"> ACTUALIZAR </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from "./mixins/update";
export default {
  data: () => ({
    updateExists: false,
    profile: { name: null },
    drawer: true,
    navStatus: true,
    open: [],
    icons: {
      project: "mdi-text-box-search-outline",
      weather: "mdi-weather-partly-snowy-rainy",
      crops: "mdi-sprout-outline",
      poly: "mdi-vector-polygon",
      dB: "mdi-database-cog-outline",
      chart: "mdi-chart-bell-curve-cumulative",
      clt: "mdi-account-circle-outline",
      set: "mdi-cog-transfer-outline",
      info: "mdi-book-information-variant",
      cale: "mdi-calendar-month-outline",
      invoice: "mdi-receipt-text-outline",
      pedido: "mdi-file-document-edit-outline",
      truck: "mdi-truck-fast-outline",
      warehouse: "mdi-warehouse",
      inventory: "mdi-package-variant-closed",
      supplier: "mdi-truck-check-outline",
      orders: "mdi-file-document-edit-outline",
      store: "mdi-store-outline",
      clock: "mdi-clock-check-outline",
      map: "mdi-map-marker-radius",
      home: "mdi-home-circle-outline",
      forklift: "mdi-forklift",
      cash: "mdi-cash-register",
    },
    menu: {
      USUARIO: [
        {
          id: 1000,
          name: "Factura Electrónica",
          icon: "invoice",
          to: "InvoiceE",
        },
        {
          id: 1,
          name: "Documentos",
          icon: "cash",
          children: [
            {
              id: 11,
              name: "Factura Electrónica",
              icon: "invoice",
              to: "InvoiceE",
            },
            {
              id: 14,
              name: "Facturas",
              icon: "invoice",
              to: "Invoices",
            },
            {
              id: 15,
              name: "Sistema POS",
              icon: "invoice",
              to: "invoices_pos",
            },
            {
              id: 16,
              name: "Notas Crédito",
              icon: "invoice",
              to: "CreditNotes",
            },
            // {
            //   id: 12,
            //   name: "Nota Débito",
            //   icon: "invoice",
            //   to: "DebitNote",
            // },
            // {
            //   id: 13,
            //   name: "Nota Crédito",
            //   icon: "invoice",
            //   to: "CreditNote",
            // },
          ],
        },
        {
          id: 4,
          name: "Reportes",
          icon: "set",
          children: [
            {
              id: 21,
              name: "Reporte de Caja",
              icon: "cash",
              to: "Cash_report",
            },
          ],
        },
        {
          id: 99,
          name: "About us",
          icon: "info",
          to: "About",
        },
      ],
      ADMIN: [
        {
          id: 1,
          name: "Documentos",
          icon: "cash",
          children: [
            {
              id: 11,
              name: "Factura Electrónica",
              icon: "invoice",
              to: "InvoiceE",
            },
            {
              id: 12,
              name: "Sistecredito",
              icon: "invoice",
              to: "Sistecredito",
            },
            {
              id: 14,
              name: "Facturas",
              icon: "invoice",
              to: "Invoices",
            },
            {
              id: 15,
              name: "Sistema POS",
              icon: "invoice",
              to: "invoices_pos",
            },
            {
              id: 16,
              name: "Notas Crédito",
              icon: "invoice",
              to: "CreditNotes",
            },
          ],
        },
        {
          id: 4,
          name: "Reportes",
          icon: "set",
          children: [
            {
              id: 1,
              name: "Reporte de Caja",
              icon: "cash",
              to: "Cash_report",
            },
            {
              id: 2,
              name: "Movimientos de Inventario",
              icon: "cash",
              to: "StockEvents",
            },
            {
              id: 3,
              name: "Resumen Diario",
              icon: "store",
              to: "Resume",
            },
          ],
        },
        {
          id: 99,
          name: "About us",
          icon: "info",
          to: "About",
        },
      ],
      Sysop: [
        {
          id: 1,
          name: "Documentos",
          icon: "cash",
          children: [
            {
              id: 11,
              name: "Factura Electrónica",
              icon: "invoice",
              to: "InvoiceE",
            },
            {
              id: 12,
              name: "Sistecredito",
              icon: "invoice",
              to: "Sistecredito",
            },
            {
              id: 14,
              name: "Facturas",
              icon: "invoice",
              to: "Invoices",
            },
            {
              id: 15,
              name: "Sistema POS",
              icon: "invoice",
              to: "invoices_pos",
            },
            {
              id: 16,
              name: "Notas Crédito",
              icon: "invoice",
              to: "CreditNotes",
            },
            // {
            //   id: 12,
            //   name: "Nota Débito",
            //   icon: "invoice",
            //   to: "DebitNote",
            // },
            // {
            //   id: 13,
            //   name: "Nota Crédito",
            //   icon: "invoice",
            //   to: "CreditNote",
            // },
          ],
        },
        {
          id: 4,
          name: "Reportes",
          icon: "set",
          children: [
            {
              id: 1,
              name: "Reporte de Caja",
              icon: "cash",
              to: "Cash_report",
            },

            {
              id: 2,
              name: "Movimientos de Inventario",
              icon: "cash",
              to: "StockEvents",
            },
            {
              id: 3,
              name: "Resumen Diario",
              icon: "store",
              to: "Resume",
            },
          ],
        },
        {
          id: 5,
          name: "Opciones",
          icon: "set",
          children: [
            // {
            //   id: 51,
            //   name: "Vendedores",
            //   to: "Menu",
            // },
            // {
            //   id: 52,
            //   name: "Autorización de Facturación",
            //   to: "Contents",
            // },
            // {
            //   id: 53,
            //   name: "Auditoría",
            //   to: "Contents",
            // },
            {
              id: 54,
              name: "Perfil Compañía",
              to: "Company",
            },
          ],
        },
        {
          id: 99,
          name: "About us",
          icon: "info",
          to: "About",
        },
        // {
        //   id: 2,
        //   name: "Ventas",
        //   icon: "cash",
        //   children: [
        //     {
        //       id: 21,
        //       name: "Reporte de Caja",
        //       icon: "cash",
        //       to: "Cash_report",
        //     },
        //     {
        //       id: 22,
        //       name: "Comprobante de Ingreso",
        //       icon: "orders",
        //       to: "Receipt_in",
        //     },
        //     {
        //       id: 23,
        //       name: "Comprobante de Egreso",
        //       icon: "orders",
        //       to: "Receipt_out",
        //     },
        //     {
        //       id: 24,
        //       name: "Devolución",
        //       icon: "orders",
        //       to: "Returns",
        //     },
        //     {
        //       id: 26,
        //       name: "Ventas por vendedor",
        //       icon: "orders",
        //       to: "Vendors",
        //     },
        //     {
        //       id: 27,
        //       name: "Resumen Diario",
        //       icon: "store",
        //       to: "Resume",
        //     },
        //   ],
        // },
        // {
        //   id: 4,
        //   name: "Inventario",
        //   icon: "warehouse",
        //   children: [
        //     {
        //       id: 41,
        //       name: "Inventario",
        //       icon: "store",
        //       to: "Inventory",
        //     },
        //     {
        //       id: 411,
        //       name: "Buscar en tiendas",
        //       icon: "store",
        //       to: "G_search",
        //     },
        //     {
        //       id: 42,
        //       name: "Ingresar Compra",
        //       icon: "store",
        //       to: "StockImport",
        //     },

        //     {
        //       id: 45,
        //       name: "Entrada a Garantía",
        //       icon: "store",
        //       to: "WarrantyIn",
        //     },
        //     {
        //       id: 46,
        //       name: "Salida de Garantía",
        //       icon: "store",
        //       to: "WarrantyOut",
        //     },
        //     {
        //       id: 47,
        //       name: "Traslado a Promoción",
        //       icon: "store",
        //       to: "Promotion",
        //     },
        //     {
        //       id: 48,
        //       name: "Traslado de Bodega",
        //       icon: "store",
        //       to: "Warehouse",
        //     },
        //     {
        //       id: 49,
        //       name: "Traslados",
        //       icon: "store",
        //       to: "WarehouseReport",
        //     },
        //   ],
        // },
        // {
        //   id: 6,
        //   name: "Reportes",
        //   children: [
        //     {
        //       id: 60,
        //       name: "Reporte Ingreso Mercancias",
        //       icon: "store",
        //       to: "StoctInReport",
        //     },
        //     {
        //       id: 61,
        //       name: "Movimientos de Inventario",
        //       icon: "store",
        //       to: "Events",
        //     },
        //     {
        //       id: 62,
        //       name: "Rotación",
        //       icon: "store",
        //       to: "Rotation",
        //     },
        //     {
        //       id: 63,
        //       name: "Rotación",
        //       icon: "store",
        //       to: "Rotation_b",
        //     },
        //     {
        //       id: 64,
        //       name: "Proveedores",
        //       icon: "store",
        //       to: "Supplier",
        //     },
        //   ],
        // },
      ],
      Auditor: [
        {
          id: 1,
          name: "Documentos",
          icon: "cash",
          children: [
            {
              id: 11,
              name: "Factura Electrónica",
              icon: "invoice",
              to: "InvoiceE",
            },
            {
              id: 12,
              name: "Sistecredito",
              icon: "invoice",
              to: "Sistecredito",
            },
            {
              id: 14,
              name: "Facturas",
              icon: "invoice",
              to: "Invoices",
            },

            {
              id: 15,
              name: "Sistema POS",
              icon: "invoice",
              to: "invoices_pos",
            },
            {
              id: 16,
              name: "Notas Crédito",
              icon: "invoice",
              to: "CreditNotes",
            },

            // {
            //   id: 12,
            //   name: "Nota Débito",
            //   icon: "invoice",
            //   to: "DebitNote",
            // },
            // {
            //   id: 13,
            //   name: "Nota Crédito",
            //   icon: "invoice",
            //   to: "CreditNote",
            // },
          ],
        },
        // {
        //   id: 2,
        //   name: "Ventas",
        //   icon: "cash",
        //   children: [
        //     {
        //       id: 21,
        //       name: "Reporte de Caja",
        //       icon: "cash",
        //       to: "Cash_report",
        //     },
        //     {
        //       id: 22,
        //       name: "Comprobante de Ingreso",
        //       icon: "orders",
        //       to: "Receipt_in",
        //     },
        //     {
        //       id: 23,
        //       name: "Comprobante de Egreso",
        //       icon: "orders",
        //       to: "Receipt_out",
        //     },
        //     {
        //       id: 24,
        //       name: "Devolución",
        //       icon: "orders",
        //       to: "Returns",
        //     },
        //     {
        //       id: 26,
        //       name: "Ventas por vendedor",
        //       icon: "orders",
        //       to: "Vendors",
        //     },
        //     {
        //       id: 27,
        //       name: "Resumen Diario",
        //       icon: "store",
        //       to: "Resume",
        //     },
        //   ],
        // },
        // {
        //   id: 4,
        //   name: "Inventario",
        //   icon: "warehouse",
        //   children: [
        //     {
        //       id: 41,
        //       name: "Inventario",
        //       icon: "store",
        //       to: "Inventory",
        //     },
        //     {
        //       id: 411,
        //       name: "Buscar en tiendas",
        //       icon: "store",
        //       to: "G_search",
        //     },
        //     {
        //       id: 42,
        //       name: "Ingresar Compra",
        //       icon: "store",
        //       to: "StockImport",
        //     },

        //     {
        //       id: 45,
        //       name: "Entrada a Garantía",
        //       icon: "store",
        //       to: "WarrantyIn",
        //     },
        //     {
        //       id: 46,
        //       name: "Salida de Garantía",
        //       icon: "store",
        //       to: "WarrantyOut",
        //     },
        //     {
        //       id: 47,
        //       name: "Traslado a Promoción",
        //       icon: "store",
        //       to: "Promotion",
        //     },
        //     {
        //       id: 48,
        //       name: "Traslado de Bodega",
        //       icon: "store",
        //       to: "Warehouse",
        //     },
        //     {
        //       id: 49,
        //       name: "Traslados",
        //       icon: "store",
        //       to: "WarehouseReport",
        //     },
        //   ],
        // },
        // {
        //   id: 6,
        //   name: "Reportes",
        //   children: [
        //     {
        //       id: 60,
        //       name: "Reporte Ingreso Mercancias",
        //       icon: "store",
        //       to: "StoctInReport",
        //     },
        //     {
        //       id: 61,
        //       name: "Movimientos de Inventario",
        //       icon: "store",
        //       to: "Events",
        //     },
        //     {
        //       id: 62,
        //       name: "Rotación",
        //       icon: "store",
        //       to: "Rotation",
        //     },
        //     {
        //       id: 63,
        //       name: "Rotación",
        //       icon: "store",
        //       to: "Rotation_b",
        //     },
        //     {
        //       id: 64,
        //       name: "Proveedores",
        //       icon: "store",
        //       to: "Supplier",
        //     },
        //   ],
        // },
        // {
        //   id: 5,
        //   name: "Opciones",
        //   icon: "set",
        //   children: [
        //     {
        //       id: 51,
        //       name: "Vendedores",
        //       to: "Menu",
        //     },
        //     {
        //       id: 52,
        //       name: "Autorización de Facturación",
        //       to: "Contents",
        //     },
        //     {
        //       id: 53,
        //       name: "Auditoría",
        //       to: "Contents",
        //     },
        //     {
        //       id: 54,
        //       name: "Perfil Compañía",
        //       to: "Company",
        //     },
        //   ],
        // },
        {
          id: 98,
          name: "Tienda",
          icon: "store",
          to: "inventory_status",
        },
        {
          id: 99,
          name: "About us",
          icon: "info",
          to: "About",
        },
      ],
      Ventas: [
        {
          id: 1,
          name: "Tienda",
          icon: "store",
          to: "inventory_status",
        },
        {
          id: 2,
          name: "Clientes",
          icon: "db",
        },
        {
          id: 99,
          name: "About us",
          icon: "info",
          to: "About",
        },
      ],
    },
    items: [],
  }),
  mounted() {},
  computed: {
    showDrawer() {
      return this.$store.getters.getMenuStatus && this.drawer;
    },
  },
  watch: {
    "$store.getters.getMenuStatus"(newVal) {
      if (newVal) {
        this.drawer = true;
      }
    },
    "$store.getters.profile"(newVal) {
      if (newVal) {
        this.items = this.menu[newVal.user_access];
      }
    },
  },
  mixins: [update],
  methods: {
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    updateForm(item) {
      //if (item.length > 0) console.log(item[0].name, item[0].to);
      if (item[0].to) this.$router.push(item[0].to);
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    logout() {
      this.$store.state.navStatus = false;
      this.$router.push("/login/");
    },
  },
};

// {
//   id: 2,
//   name: "Ventas",
//   icon: "cash",
//   children: [
//     {
//       id: 21,
//       name: "Reporte de Caja",
//       icon: "cash",
//       to: "Cash_report",
//     },
//     {
//       id: 22,
//       name: "Comprobante de Ingreso",
//       icon: "orders",
//       to: "Receipt_in",
//     },
//     {
//       id: 23,
//       name: "Comprobante de Egreso",
//       icon: "orders",
//       to: "Receipt_out",
//     },
//     {
//       id: 24,
//       name: "Devolución",
//       icon: "orders",
//       to: "Returns",
//     },
//     {
//       id: 26,
//       name: "Ventas por vendedor",
//       icon: "orders",
//       to: "Vendors",
//     },
//     {
//       id: 27,
//       name: "Resumen Diario",
//       icon: "store",
//       to: "Resume",
//     },
//   ],
// },
// {
//   id: 4,
//   name: "Inventario",
//   icon: "warehouse",
//   children: [
//     {
//       id: 41,
//       name: "Inventario",
//       icon: "store",
//       to: "Inventory",
//     },
//     {
//       id: 411,
//       name: "Buscar en tiendas",
//       icon: "store",
//       to: "G_search",
//     },
//     {
//       id: 42,
//       name: "Ingresar Compra",
//       icon: "store",
//       to: "StockImport",
//     },

//     {
//       id: 45,
//       name: "Entrada a Garantía",
//       icon: "store",
//       to: "WarrantyIn",
//     },
//     {
//       id: 46,
//       name: "Salida de Garantía",
//       icon: "store",
//       to: "WarrantyOut",
//     },
//     {
//       id: 47,
//       name: "Traslado a Promoción",
//       icon: "store",
//       to: "Promotion",
//     },
//     {
//       id: 48,
//       name: "Traslado de Bodega",
//       icon: "store",
//       to: "Warehouse",
//     },
//     {
//       id: 49,
//       name: "Traslados",
//       icon: "store",
//       to: "WarehouseReport",
//     },
//   ],
// },
// {
//   id: 6,
//   name: "Reportes",
//   children: [
//     {
//       id: 60,
//       name: "Reporte Ingreso Mercancias",
//       icon: "store",
//       to: "StoctInReport",
//     },
//     {
//       id: 61,
//       name: "Movimientos de Inventario",
//       icon: "store",
//       to: "Events",
//     },
//     {
//       id: 62,
//       name: "Rotación",
//       icon: "store",
//       to: "Rotation",
//     },
//     {
//       id: 63,
//       name: "Rotación",
//       icon: "store",
//       to: "Rotation_b",
//     },
//     {
//       id: 64,
//       name: "Proveedores",
//       icon: "store",
//       to: "Supplier",
//     },
//   ],
// },
// {
//   id: 5,
//   name: "Opciones",
//   icon: "set",
//   children: [
//     {
//       id: 51,
//       name: "Vendedores",
//       to: "Menu",
//     },
//     {
//       id: 52,
//       name: "Autorización de Facturación",
//       to: "Contents",
//     },
//     {
//       id: 53,
//       name: "Auditoría",
//       to: "Contents",
//     },
//     {
//       id: 54,
//       name: "Perfil Compañía",
//       to: "Company",
//     },
//   ],
// },
</script>
<style lang="css">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

.v-main {
  font-family: "Roboto Condensed", sans-serif !important;
}

.posweb {
  font-family: "Roboto Condensed", sans-serif !important;
}

.navigation-drawer {
  font-size: 1rem !important;
  font-family: "Roboto Condensed", sans-serif !important;
  max-width: 600px;
}

.v-dialog {
  font-family: "Roboto Condensed", sans-serif !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.bg-pos {
  /* background: #cfd8dc5e; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}
</style>
