<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md10 lg8>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card height="100%" elevation="4">
              <v-card-title>
                <h3>Traslado de Bodega</h3>
              </v-card-title>
              <v-card-text>
                <b>{{ this.store.PartyName }}</b>
                <br />
                {{ this.store.store_address + " , " + this.store.store_city }}<br />
              </v-card-text>
              <v-card-text>
                <h3></h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card height="100%" elevation="4">
              <v-card-text>
                <v-select
                  :items="stores"
                  label="Bodega Destino"
                  dense
                  item-value="store_id"
                  item-text="store_name"
                  outlined
                  clearable
                  @change="changeStore"
                  hide-details
                  return-object
                >
                </v-select>
                ID: {{ this.client.PartyIdentification }}<br />
                Nombre: {{ this.client.PartyName }} <br />
                Email: {{ this.client.client_email }}<br />
                Tel: {{ this.client.client_phone }}<br />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers_items"
              :items="items"
              :items-per-page="10"
              item-key="id"
              small
              class="elevation-4"
              :loading="loading_status"
              loading-text="Cargando ..."
            >
              <template #top>
                <v-toolbar flat>
                  <h3>Productos</h3>
                  <v-spacer></v-spacer>
                  <v-icon @click="search_dialog = !search_dialog"> mdi-plus </v-icon>
                </v-toolbar>
              </template>
              <template v-slot:[`item.category`]="{ item }">
                {{ item.category + " / " + item.groupName }}
              </template>
              <template v-slot:[`item.cantidad`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item['cantidad']"
                  @cancel="cancel"
                  cancel-text="Cancelar"
                  @save="save"
                  save-text="Guardar"
                  @open="open"
                  @close="close"
                  large
                >
                  {{ item["cantidad"] }}
                  <template v-slot:input>
                    <v-text-field
                      label="Cantidad"
                      type="number"
                      :rules="[
                        f_qty.qtyMin(item['cantidad'], 1),
                        f_qty.qtyMax(item['cantidad'], item['qty']),
                      ]"
                      v-model="item['cantidad']"
                      @input="checkQty(item)"
                      single-line
                      outlined
                      :hint="qtyAlert"
                      class="pt-4"
                      dense
                    />
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="deleteItem(item)">
                  mdi-delete-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="6"> </v-col>
          <v-col cols="12" sm="6">
            <v-form v-model="validatemotive" ref="motiveform" @submit="save_receipt">
              <v-card height="100%">
                <v-toolbar flat dense>
                  <h3>Concepto de la salida de garantía</h3>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-select
                    v-model="motive"
                    :items="FP"
                    item-value="codigo"
                    item-text="valor"
                    return-object
                    outlined
                    :rules="f_required"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn x-large color="success" :disabled="!valide_qry" type="submit"
                    >Registrar Traslado
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>

    <v-dialog v-model="client_dialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <h3>Cliente</h3>
          <v-spacer></v-spacer>
          <v-icon @click="client_dialog = !client_dialog"> mdi-close </v-icon>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="client.typeid"
                hide-details
                :items="id_lst"
                item-value="code"
                item-text="name"
                label="Tipo de documento"
                placeholder="Vendedor"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="client.PartyIdentification"
                label="Número de documento"
                ref="idNumber"
                hide-details
                outlined
                @keyup="validateID"
                autofocus
                clearable
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="client.PartyName"
                label="Nombre Comopelto"
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="client.AddressLine"
                label="Dirección"
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="client.CityName"
                label="Ciudad"
                placeholder="Ciudad"
                hide-details
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            color="success"
            type="button"
            @click="client_dialog = !client_dialog"
            >Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="search_dialog" max-width="800px">
      <v-form v-model="search_valid" ref="searchform" @submit="search_inventory">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar flat>
              <h5>Buscar en el Inventario</h5>
              <v-spacer></v-spacer>
              <v-icon @click="search_dialog = !search_dialog"> mdi-close </v-icon>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row class="pa-2">
              <v-col cols="6" sm="3">
                <v-select
                  v-model="filters.categoria"
                  :items="CT"
                  item-value="codigo"
                  item-text="valor"
                  label="Categoria"
                  placeholder="Categoria"
                  hide-details="auto"
                  outlined
                />
              </v-col>
              <v-col cols="6" sm="3">
                <v-select
                  v-model="filters.marca"
                  :items="MR"
                  item-value="codigo"
                  item-text="valor"
                  label="Marca"
                  placeholder="Marca"
                  clearable
                  hide-details="auto"
                  outlined
                />
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  v-model="filters.refe"
                  label="Referencia"
                  clearable
                  hide-details="auto"
                  outlined
                  required
                />
              </v-col>
              <v-col cols="6" sm="3">
                <v-btn
                  :disabled="!search_valid"
                  block
                  color="success"
                  x-large
                  type="submit"
                >
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="inventory_lst"
              :items-per-page="5"
              class="elevation-4"
              mobile-breakpoint="0"
              item-key="ean"
              :loading="loading_status"
              loader-height="10"
              small
              loading-text="Cargando ..."
            >
              <template v-slot:top> </template>
              <template v-slot:[`item.category`]="{ item }">
                {{ item.category + " / " + item.groupName }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="add2cart(item)">
                  mdi-plus-box-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-form>
      <v-snackbar
        v-model="productAdded"
        :timeout="timeout"
        absolute
        centered
        dark
        color="orange"
        elevation="24"
      >
        Producto agregado ...
      </v-snackbar>
    </v-dialog>

    <v-navigation-drawer
      right
      class="navigation-drawer"
      :value="showPrint"
      width="400px"
      app
    >
      <v-card height="100%">
        <v-card-title class="pa-0">
          <v-toolbar dark tile flat>
            <h5>Salida de Garantía</h5>
            <v-spacer></v-spacer>
            <v-icon @click="showPrint = !showPrint"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-img height="100%" width="100%" class="white" :src="dataURL"></v-img>
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-toolbar class="fixed-footer">
            <v-spacer></v-spacer>
            <v-btn large block color="success" @click="print_doc()"> Imprimir </v-btn>
          </v-toolbar>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <v-snackbar
      v-model="productAdded"
      :timeout="timeout"
      height="200px"
      absolute
      centered
      dark
      color="orange"
      elevation="24"
    >
      Producto agregado ...
    </v-snackbar>
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";

import createClient from "../utils/createClient.js";
import createPayment from "../utils/createPayment.js";
import create_item from "../utils/create_item.js";

export default {
  components: {},
  data() {
    return {
      search: "",
      searcText: "",
      issueDate: getToday(),
      invoices: [],
      searchvalid: false,
      validatemotive: false,
      client_dialog: false,
      payment_dialogo: false,
      concepts_dialog: false,
      product_dialog: false,
      loading_status: false,
      validConcept: false,
      payvalid: false,
      showPrint: false,
      receipt: null,
      signature: null,
      payment: createPayment(),
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      searchOptions: [
        { code: "client_id", name: "Cédula" },
        { code: "order_number", name: "Documento" },
        { code: "client_phone", name: "Teléfono" },
      ],
      headers: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "brand",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "colorName",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "cantidad",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_items: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "brand",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "colorName",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "cantidad",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      store: createClient(),
      concept: create_item(),
      concepts: [],
      concept_lst: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        codigo: "01",
        valor: "EFECTIVO",
      },
      FP: [],
      client: createClient(),
      signatureStock: null,
      f_required: [(v) => !!v || "! Campo requerido !"],
      f_qty: {
        qtyMin(value, min) {
          return (parseFloat(value) || "") >= min || `Cantidad mínimo ${min}`;
        },
        qtyMax(value, max) {
          return (parseFloat(value) || "") <= max || `Cantidad máximo de ${max}.`;
        },
      },
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      dataURL: "",
      items: [],
      motive: { codigo: null, valor: null },
      returns: {
        seller: {},
        paidTo: {},
        items: [],
        payments: [],
      },
      validate_return: false,
      CT: null,
      MR: null,
      colors: [],
      search_valid: false,
      filters: { categoria: "", marca: "", refe: "", id_pos: "" },
      search_dialog: false,
      inventory_lst: [],
      timeout: 500,
      timeout_error: 2000,
      productAdded: false,
      stores: [],
      qtyAlert: "",
      valide_qry: false,
    };
  },
  mounted() {
    this.store = window.store;
    this.FP = window.settings["TB"];
    this.CT = window.settings.CATEGORIA;
    this.MR = window.settings.MARCA;
    this.colors = window.settings.COLOR;
    this.client = {
      PartyName: null,
      PartyIdentification: null,
      client_email: null,
      client_phone: null,
    };
    this.get_stores();
  },
  methods: {
    checkQty(e) {
      if (parseFloat(e.cantidad) > parseFloat(e.qty)) {
        e.cantidad = parseFloat(e.qty);
        this.qtyAlert = "Cantidad máxima :" + parseFloat(e.qty);
      }
      if (parseFloat(e.cantidad) < 1) {
        e.cantidad = 1;
        this.qtyAlert = "Minima ";
      }
    },
    save() {},
    cancel() {},
    open() {},
    close() {},
    onBegin() {
      //console.log('=== Begin ===');
    },
    onEnd() {
      //console.log('=== End ===');
    },
    changeStore(e) {
      this.client = {
        PartyName: e.store_name,
        PartyIdentification: e.store_id,
        client_email: e.store_email,
        client_phone: e.store_phone,
      };
    },
    get_stores() {
      var qry = {
        store: window.store.store_id,
        table: "stores",
        filters: [],
      };
      qry.filters.push({
        field: "store_owner",
        operator: "=",
        value: window.store.store_id.substring(0, 6),
      });
      this.loading_status = true;
      webserver("get_table", qry, (data) => {
        var index = data.findIndex((itm) => itm.store_id === window.store.store_id);
        data.splice(index, 1);
        this.loading_status = false;
        this.stores = data;
      });
    },
    get_name_typeId(e) {
      var index = this.id_lst.findIndex((itm) => itm.code === e);
      if (index >= 0) {
        return this.id_lst[index].name;
      }
    },
    get_concept_text(e) {
      var index = this.concept_lst.findIndex((itm) => itm.codigo === e);
      if (index >= 0) {
        return this.concept_lst[index].valor;
      }
    },
    validateID(e) {
      if (e.keyCode === 13) {
        var qry = {
          store: window.store.store_id,
          PartyIdentification: this.client.PartyIdentification,
        };
        webserver("get_client_info", qry, (data) => {
          if (data) {
            this.client = data;
          }
        });
      }
    },
    add2cart(e) {
      const newItem = { ...e };
      newItem.qty = newItem.cantidad;
      delete newItem.order_date;
      newItem.cantidad = 1;
      var index = this.items.findIndex((itm) => itm.ean === newItem.ean);
      if (index === -1) {
        this.items.push(newItem);
      } else {
        this.items[index].cantidad = this.items[index].cantidad + newItem.cantidad;
      }
      this.updateTotal();
      this.product_dialog = false;
      this.productAdded = true;
    },
    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;

      this.items.forEach((itm) => {
        this.totals.qty += parseFloat(itm.cantidad);
        this.totals.total += parseFloat(itm.cantidad) * parseFloat(itm.precio);
      });
      this.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;

      if (this.totals.unpaid === 0) {
        this.payment_dialogo = false;
        this.topay = true;
        this.save_receipt();
      } else {
        this.topay = false;
      }
    },
    get_report(e) {
      e.preventDefault();
      this.$refs.searchform.validate();
      //console.log(this.searchvalid);
      if (this.searchvalid) {
        var qry = {
          store: window.store.store_id,
          table: "items",
        };
        qry.filters = [
          {
            field: "id_pos",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "cantidad",
            operator: ">",
            value: 0,
          },
          {
            field: "categoria",
            operator: "=",
            value: "G",
          },
        ];
        //console.log(qry);
        webserver("get_table", qry, (data) => {
          //console.log(data);
          if (data) {
            data.forEach((itm) => {
              itm.category = window.settings["CATEGORIA"].find(
                (item) => item.codigo == itm.categoria
              ).valor;
              itm.groupName = window.settings["GRUPO"].find(
                (item) => item.codigo == itm.grupo
              ).valor;
              itm.brand = window.settings["MARCA"].find(
                (item) => item.codigo == itm.marca
              ).valor;
              itm.colorName = window.settings["COLOR"].find(
                (item) => item.codigo == itm.color
              ).valor;
              this.concepts = data;
            });
          }
        });
      }
    },
    search_inventory(e) {
      e.preventDefault();
      this.filters.id_pos = window.store.store_id;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: this.filters,
      };

      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        this.inventory_lst = data;
      });
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    deleteItem(e) {
      var index = this.items.findIndex((itm) => itm.ean === e.ean);
      this.items.splice(index, 1);
    },
    delete_payment(e) {
      this.payments.splice(e, 1);
      this.updateTotal();
    },
    save_receipt(e) {
      e.preventDefault();
      this.$refs.motiveform.validate();

      if (this.valide_qry) {
        var qry = {
          doc: {
            doc_type: "TB",
            doc_value: this.totals.total,
            store: this.client.PartyIdentification,
            userId: window.profile.user_email,
            vendor: window.store.store_id,
          },
          items: this.items,
          paidTo: this.client,
          seller: window.seller,
          store: window.store.store_id,
          payments: [
            {
              payment_code: this.motive.codigo,
              payment_concept: this.motive.valor,
              payment_reference: "",
              payment_value: this.totals.total,
            },
          ],
        };
        //console.log(qry, window.seller);
        this.loading_vendors = true;
        webserver("put_stock", qry, (data) => {
          this.resetDoc();
          this.loading_vendors = false;
          //console.log(data);
          this.print_receipt(data);
        });
      }
    },
    resetDoc() {
      this.motive = { codigo: null, valor: null };
      this.payments = [];
      this.items = [];
      this.client = createClient();
      this.valide_qry = false;
      this.client = {
        PartyName: null,
        PartyIdentification: null,
        client_email: null,
        client_phone: null,
      };
    },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },
    print_receipt(data) {
      var LS = 50;
      var itemsQty = data.items.length * LS * 3;
      const elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 1800 + itemsQty;
      //var total = 0;
      var center = elem.width / 2;
      var left = 10;
      var line = 150;
      var right = elem.width - left;
      const ctx = elem.getContext("2d");

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(window.seller.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(window.seller.PartyName, center, line);
      line += LS;
      ctx.fillText("Nit : " + window.seller.PartyIdentification, center, line);

      line += LS;
      ctx.fillText(
        window.seller.AddressLine + ", " + window.seller.CityName,
        center,
        line
      );
      line += LS;
      ctx.font = "bold 35px Arial";
      ctx.fillText("RECIBO DE CAJA", center, line);
      ctx.font = "bold 40px Arial";
      line += LS;
      ctx.fillText("No. " + data.document.doc_number, center, line);
      line += LS;
      ctx.fillText(data.document.issueDate, center, line);
      line += LS;
      ctx.textAlign = "start";
      ctx.fillText("C.C./Nit : " + data.buyer.PartyIdentification, left, line);
      line += LS;
      ctx.fillText("Nombre    : " + data.buyer.PartyName, left, line);
      line += LS;
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Concepto", center, line);
      line += LS;
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;
      data.items.forEach((row) => {
        var price = parseFloat(row.precio) * parseFloat(row.cantidad);
        var description =
          row.brand + "-" + row.refe + "-" + row.colorName + "-" + row.talla;
        ctx.textAlign = "start";
        ctx.fillText(description, left, line);
        ctx.textAlign = "end";
        ctx.fillText(price.toLocaleString(), right, line);
        line += LS;
      });
      ctx.beginPath();
      ctx.moveTo(left, line - 10);
      ctx.lineTo(right, line - 10);
      ctx.stroke();
      line += LS;

      line += LS;

      ctx.textAlign = "center";
      ctx.fillText("Motivo Devolución", center, line);
      line += LS;

      data.payments.forEach((row) => {
        ctx.textAlign = "start";
        ctx.fillText(row.payment_concept + " - " + row.payment_reference, left, line);
        ctx.textAlign = "end";
        ctx.fillText(parseInt(row.payment_value).toLocaleString(), right, line);
        line += LS;
      });
      line += LS;

      line += LS;
      line += LS;

      this.dataURL = elem.toDataURL();
      //this.receipt = this.dataURL;
      this.showPrint = true;
      // var a = window.open("", "", "height=600, width=400");
      // a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      // a.print();
      // a.document.close();
      // setTimeout(() => {
      //   a.close();
      // }, 500);
    },
  },
  watch: {
    motive: function () {
      if (this.motive.codigo && this.totals.total > 0 && this.client.PartyName) {
        this.valide_qry = true;
      }
    },
    totals: function () {
      if (this.motive.codigo && this.totals.total > 0 && this.client.PartyName) {
        this.valide_qry = true;
      }
    },
    client: function () {
      if (this.motive.codigo && this.totals.total > 0 && this.client.PartyName) {
        this.valide_qry = true;
      }
    },
  },
};
</script>

<style>
.sign {
  border: darkgray solid 1px;
}
</style>
